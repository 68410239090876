/*=============================================================================
 chat/PaneRooms.tsx - Left pane for search/rooms

 - ROOMS        : Joined room list
   users/{uid}.rooms
   rooms/{roomId}.backURL
   rooms/{roomId}.title

 - Flex (Detail View) (2021-06-06)
    outer layer: flex flex-wrap
    each item: px-1 py-2 w-full sm:w-1/2 2xl:w-1/3 3xl:w-1/4
 - Grid (Detail View)
    outer layer: grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 gap-0.5
 - tailwind container restricts max-width
 - States (view mode, show, align, o2o) are saved to LocalStorage,
   so maintained even between sessions (next login) in the same machine.
 - FALLBACK_BKG image fallback cannot cover the cases like network error.
  onError={ev => { (ev.target as HTMLImageElement).src = FALLBACK_BKG; }}  // fails to work

 (C) 2020 SpacetimeQ INC
=============================================================================*/
import { useState, } from 'react';
import { useAppDispatch } from 'app/store';
import { ToggleButtonSvg, ButtonC, } from 'ui/ui';
import { SearchBox, } from 'ui/SearchBox';
import { AvatarRoomImgs, } from 'ui/Avatars';
import { SvgIcon, } from 'utils/svg';
import { datetimeStr, } from 'utils/datetime';
import { cC, cCo, cL, cLo, cLoIf, } from 'utils/util';
import { FALLBACK_BKG, } from 'app/res';
import { getLS, setLS, getLSBool, setLSBool, } from 'utils/useLocalStorage';
import { msgEnterRoom, msgExitRoom, } from 'features/msgs/msgsSlice';
import { useCurrentUser, } from 'features/users/usersSlice';
import { useRoomById, useCurrentRoom, } from 'features/rooms/roomsSlice';
import { PaneHead, PopupMenu, MenuItemExit } from 'ui/menus';
import { EditRoomModal } from './EditRoom';
import { ButtonTag } from 'ui/Tags';
import { LImg } from 'utils/Image';

//-----------------------------------------------------------------------------
// ROOMS - per current user that is subscribed
//-----------------------------------------------------------------------------
type TLVMode = 'Detail'|'List'|'Compact'|'Icon';  // ListView mode
const RItem = (name: string) => 'rooms_' + name;  // prefix for the LocalStorage item

/**
 * Pane rooms with a pane head and rooms list
 */
export default function PaneRooms({ className }: IClassNameObj) {
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const [align, setAlign] = useState(getLSBool(RItem('align')));  // align width or height
  const [o2o,   setO2o]   = useState(getLSBool(RItem('o2o'), false));  // one-to-one
  const [vmode, setVMode] = useState<TLVMode>(getLS<TLVMode>(RItem('vmode')) || 'Detail');
  const [searchText, setSearchText] = useState('');
  const cRoom = useCurrentRoom();
  if (!user)
    return null;
  const nRooms = user.rooms?.length || 0;
  const title = `${nRooms} CHANNEL${nRooms > 0 ? 'S' : ''}`;
  // click handlers
  const handleAlignClick = () => {
    setAlign(!align);
    setLSBool(RItem('align'), !align);
  }
  const handleO2oClick = () => {
    setO2o(!o2o);
    setLSBool(RItem('o2o'), !o2o);
  }
  const handleVmodeClick = (vm: TLVMode) => {
    setVMode(vm);
    setLS<TLVMode>(RItem('vmode'), vm);
  }
  const vModeMenuItem = (vm: TLVMode, classX?: TClassName) => ({
    item:     `${vm} View`,
    disabled: vmode === vm,
    onClick:  () => handleVmodeClick(vm),
    classX,
  });

  // TODO: What should we do with the exited rooms: user.xRooms?
  const RoomItemList: IFClassName<{ iconOnly?: boolean; }> = ({ className, iconOnly }) =>
    <div {...{className}}>
      {user.rooms?.map(roomId =>
        <RoomItem
          key={roomId}
          {...{roomId, vmode, iconOnly, align, searchText, setSearchText, o2o}}
          current={!!cRoom && (roomId === cRoom.id)}
        />)}
    </div>;

  return (
    <div {...{className}}>
      <PaneHead
        {...{title}}
        iconPath="bubble_comment"
      >
        <SearchBox {...cLo("py-0.5 pl-8")} {...{setSearchText}} />
        <ToggleButtonSvg
          Path ="menu_unaligned"
          Path2="menu_aligned"
          isDisabled={!nRooms}
          isSelected={align}
          onPress={handleAlignClick}
        />
        <EditRoomModal uids={[user.uid]} />
        <PopupMenu
          {...{title}}
          menuItems={[
            vModeMenuItem('Detail'),
            vModeMenuItem('List'),
            vModeMenuItem('Compact'),
            {
              item:     `Show ${o2o ? "All" : "1:1 Only"}`,
              disabled: !nRooms,
              onClick:  handleO2oClick,
              classX:   "border-dotted border-t-2 border-gray-500",
            },
            {
              item:     <MenuItemExit text="Exit Channel" />,
              disabled: !cRoom,
              onClick:  () => dispatch( msgExitRoom() ),
              classX:   "border-t border-gray-500",
            },
          ]}
          Path="dots_vertical"
        />
      </PaneHead>
      <div {...cCo("overflow-y-scroll cursor-pointer border-r border-b-2 border-gray-400",
            cRoom, "max-h-[40vh]", "Sq_h-roomlist")}
      >
        <RoomItemList {...cLoIf(vmode === 'Detail',
          "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5",
          "gap-0.5")}
        />
        <RoomItemList {...cLoIf(vmode !== 'Detail')} />
      </div>
    </div>
  );
}

//-----------------------------------------------------------------------------
// Utility functions
//-----------------------------------------------------------------------------
interface IRoomItemProps {
  readonly roomId: TRoomID;
  current?:        boolean;  // this room is the current (entered) room
  vmode:           TLVMode;
  iconOnly?:       boolean;
  align:           boolean;  // to set img width/height fixed (aligned) or auto
  searchText?:     string;   // search text
  setSearchText?:  (text: string) => void;
  o2o?:            boolean;  // one-to-one room only
};
/**
 * Each Room in various view modes
 * - relative/absolute pair for <CreatedAt> - 'relative' sets the outer boundary
 */
const RoomItem = ({
  roomId, current, vmode, iconOnly, align, searchText, setSearchText, o2o
}: IRoomItemProps) => {
  // console.log("RoomItem:roomId=", roomId);
  const room = useRoomById(roomId);  // updated by subscribed doc
  const dispatch = useAppDispatch();

  if (!room) {
    console.log("RoomItem:No room! roomId=", roomId);
    return null;
  }

  // ---------------------------------------------------------------------------------------
  // TODO: case-insensitive; build a search string that includes title, user names, emails,
  // hashtags, etc
  if (o2o && room.users?.length !== 2)
    return null;  // filter with search text
  
  let aSP: string[] = [];  // array of search patterns from searchText
  if (searchText) {
    aSP = searchText?.split(' ');  // toLowerCase()
  }
  if (aSP.length && aSP.every(p => {
      if ( room.tags?.includes(p)
        || room.title.includes(p) )
        return false;  // exit
      return true;     // continue
    }) )
    return null;  // filter with search text
  // ---------------------------------------------------------------------------------------

  const onClickEnterHandle = () => {
    dispatch(current  // switch roomId in msgsSlice
      ? msgExitRoom()
      : msgEnterRoom(roomId)
    );
  }
  const onClickTagHandle = (tag: string) => {
    setSearchText?.(aSP.includes(tag)  // already selected
      ? aSP.filter(p => p !== tag).join(' ')
      : searchText ? searchText + ' ' + tag : tag);
  }
  // const handleContextMenu: TDivClickHandler = (e) => {
  //   e.preventDefault();
  //   console.log(roomId, e.clientX, e.clientY);
  // }
  const twHover = current
    ? "dark:bg-blue-600 bg-blue-200 dark:hover:bg-blue-800 hover:bg-blue-300"
    : "dark:border-gray-600 border-gray-300 dark:hover:bg-gray-800 hover:bg-gray-300";
  const TitleSection: IFClassName = ({ className }) =>
    <h1 {...cLo("flex items-center font-bold dark:text-gray-100 text-gray-700", className)}>
      <ButtonC
        classX={cC("BC_Room", current, "BCR_Exit", "BCR_Enter")}
        onPress={onClickEnterHandle}
      >
        <SvgIcon {...cLo("w-6 p-0.5")}
          Path={current ? "door_exit" : "door_enter"}
          strokeWidth={1}
        />
      </ButtonC>
      <div {...cLo("ml-2 flex flex-col")}>
        <div>{room.title}</div>
        <div {...cLo("flex flex-row flex-wrap text-xs")}>
          {room.tags?.map((tag, i) =>
            <ButtonTag key={i}
              {...cLo("mt-1")}
              {...{tag}}
              selected={aSP?.includes(tag)}
              onPress={(_ev) => onClickTagHandle(tag)}
            />)}
        </div>
      </div>
    </h1>;
  const CoverImg: IFClassName = ({ className }) =>
    <LImg {...cLo("object-cover", className)}
      src={room.backURL}
      errorImg={FALLBACK_BKG}
      alt="room"
      {...(iconOnly && {onClick: onClickEnterHandle})}
    />;
  const CreatedAt: IFClassName = ({ className = "text-3xs sm:text-xs sm:font-bold" }) =>
    <p {...cLo(className, "absolute bottom-0 right-0 m-2 whitespace-nowrap",
        "dark:text-green-400 text-green-700")}
    >
      {datetimeStr(room.createdAt)}
    </p>;
  const ListColHeader: React.FC = ({ children }) =>
    <header {...cLo("flex-auto min-w-0 my-2")}>
      <TitleSection className="text-sm mb-1" />
      {children}
    </header>;
  const twFlexItems = "relative flex items-center justify-between";

  return room
  ? <div {...cLo((vmode !== 'Detail') && cL("border-b border-gray-300", twHover))}>
      <article {...cLoIf(vmode === 'Detail',
        "relative overflow-hidden dark:bg-gray-700 m-2 rounded-lg shadow-md", twHover)}
      >
        <CoverImg {...cCo("w-full", align, "h-40", "h-auto")} />
        <header {...cLo("flex flex-col items-left justify-between leading-tight p-1")}>
          <TitleSection className="text-base" />
          <CreatedAt />
        </header>
        <footer {...cLo(twFlexItems, "leading-none m-2 p-1 overflow-x-scroll")}>
          <AvatarRoomImgs {...{room}} />
        </footer>
      </article>

      <article {...cLoIf(vmode === 'List', twFlexItems)}>
        <CoverImg {...cCo("h-20 mx-2 rounded-md shadow-md", align, "w-20", "w-auto")} />
        <ListColHeader>
          <AvatarRoomImgs {...{room}} {...cLo("ml-1")} />
        </ListColHeader>
        <CreatedAt />
      </article>

      <article {...cLoIf(vmode === 'Compact', twFlexItems)}>
        <CoverImg {...cCo("h-12 mx-2 rounded-md shadow-md", align, "w-12", "w-auto")} />
        <ListColHeader>
          <CreatedAt {...cLo("text-3xs")} />
        </ListColHeader>
        <AvatarRoomImgs {...{room}}
          {...cLo("mr-2 -mt-2")}
          clsSize="w-6 h-6 lg:w-8 lg:h-8"
        />
      </article>

      <CoverImg {...cLoIf(iconOnly, "h-8 border-2 hover:border-yellow-400",
        align   ? "w-8" : "w-auto",
        current ? "border-red-400" : "border-gray-400")}
      />
    </div>
  : null;
}
