/*=============================================================================
 StepGuide.tsx - Step Progression Guide

 - Vertical steps only for now
 - When you need to load tailwind classes runtime, be sure to list them during JIT compiling

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useEffect } from 'react';
import { SvgIcon } from 'utils/svg';
import { cLo } from 'utils/util';
import { PendingDlg } from 'ui/forms';

const STEP_GUIDE_ICONS = {
  email:    <SvgIcon strokeWidth={1} vLen={20} Path="email_auth" />,
  wave:     <SvgIcon d="M22 12h-4l-3 9L9 3l-3 9H2" />,
  complete: <SvgIcon d="M22 11.08V12a10 10 0 11-5.93-9.14 M22 4L12 14.01l-3-3" />
};

type TStepGuideData = { [K in TStepGuideMode]: IStepProps; };
type TStepGuideMode = 'Login' | 'Signup';
interface IStepItemProps {
  icon: keyof typeof STEP_GUIDE_ICONS;
  step: string;
  desc: React.ReactNode;
};
interface IStepProps {
  stepClass: string;
  item:      IStepItemProps[];
};

interface IStepGuideProps extends IClassNameObj {
  mode: TStepGuideMode;
  step: number;  // current step 0..
};
export function StepGuide({ className, mode, step }: IStepGuideProps) {
  const [guide,  setGuide]  = useState<TStepGuideData | null>(null);
  const [errMsg, setErrMsg] = useState<string0>(null);
  const [retry,  setRetry]  = useState(0);
  useEffect(() => {
    const fetchStepGuide = async () => {
      try {
        const res = await fetch('asset/stepguide.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept':       'application/json'
            }
          });
        if (!res.ok)
          throw Error(res.statusText);
        const json: typeof guide = await res.json();
        if (json)
          setGuide(json);
      } catch (error) {
        console.error(error);
        setErrMsg(error);
      }
    }
    fetchStepGuide();
  }, [retry]);

  return (
    <div {...{className}}>
      {guide
      ? guide[mode].item.map((s, i) =>
          <div key={s.step} className="flex relative pb-12">
            <div {...cLo("flex-shrink-0 w-10 h-10 rounded-full shadow-lg",
              (step >= i) ? "bg-indigo-500" : "dark:bg-gray-400 bg-gray-200",
              "inline-flex items-center justify-center text-white relative z-10")}>
              {STEP_GUIDE_ICONS[s.icon]}
            </div>
              {(i < guide[mode].item.length - 1) &&
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 dark:bg-gray-200 bg-gray-400 pointer-events-none" />
              </div>}
            <div className="flex-grow pl-4 font-sans">
              <h2 {...cLo("font-bold text-sm mb-1 tracking-wider", guide[mode].stepClass)}>
                {s.step}
              </h2>
              <p className="leading-relaxed dark:text-gray-200 text-gray-800">
                {s.desc}
              </p>
            </div>
          </div>
        )
      : <PendingDlg
          text={errMsg ? errMsg + "! Click to retry..." : "Loading guides..."}
          colors="text-black dark:bg-yellow-200 bg-yellow-400"
          onClick={() => setRetry(retry + 1)}
        />}
    </div>
  );
}
