/*=============================================================================
 Notis.tsx - Notifications

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useRef, useCallback, } from 'react'
import { useAuthCtx } from 'app/AuthContext';
import { NotiIconButton, } from 'ui/ui';
import { useClickOutside, } from 'ui/menus';
import { cLo, cLoIf, } from 'utils/util';

interface INotisLineProps {
  url:       string;
  avatar:    string;  // avatar img url
  name:      string;
  msg:       string;
  timestamp: string;
};
const NotisLine = ({ url, avatar, name, msg, timestamp }: Partial<INotisLineProps>) => {
  return (
    <a href={url} className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
      <img
        className="flex-shrink-0 h-8 w-8 rounded-full object-cover mx-1"
        src={avatar} alt="avatar"
      />
      <p className="mx-2 leading-tight">
        <span className="text-blue-600 text-xs font-bold">{name}</span>
        <span className="text-gray-600 text-sm font-normal ml-1">{msg}</span>
        <span className="text-purple-400 text-3xs font-mono ml-1">{timestamp}</span>
      </p>
    </a>
  );
}

export default function Notis() {
  const { user } = useAuthCtx();
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, useCallback(() => setShow(false), []));
  const NOTIS_DEMO: INotisLineProps[] = [
    {
      url:    "#",
      avatar: "https://randomuser.me/api/portraits/women/17.jpg",
      name:   "Alice@SpacetimeQ",
      msg:    `Hi ${user?.name || user?.email}! Welcome to SpacetimeQ. We are now in a closed test mode. Thank you for your cooperation!`,
      timestamp: "2021-05-01 01:35:52",
    },
    {
      url:    "#",
      avatar: "https://randomuser.me/api/portraits/men/18.jpg",
      name:   "amdin@SpacetimeQ",
      msg:    "During the test period, any data in our server can possibly be changed without warning.",
      timestamp: "2021-05-01 01:40:10",
    },
  ];
  return (
    <span className="px-1">
      <NotiIconButton
        notis={user ? NOTIS_DEMO.length : 0}
        {...(user && { onPress: () => setShow(!show) })}
      />
      <div {...{ref}}
        {...cLoIf(show,
          "absolute right-12 top-10 bg-white rounded-md shadow-lg overflow-hidden z-30 w-80")}
      >
        <div className="py-2">
          {NOTIS_DEMO.map((notis, i) => <NotisLine key={i} {...notis} />)}
        </div>
        <div {...cLo("block dark:bg-gray-300 dark:text-black",
          "bg-gray-800 text-white text-center font-bold py-2")}
        >
          See all notifications
        </div>
      </div>
    </span>
  );
}
