import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import 'out/tailwind.o.css';  // generated from 'out/tailwind.tw.css'
import { AuthProvider } from 'app/AuthContext';
import App from 'app/App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { store }    from 'app/store';
import { Provider } from 'react-redux';

if (!process.env.NODE_ENV ||
     process.env.NODE_ENV === 'production') {
  console.log = function() {}  // suppress all console.log in production mode
}
// use console.info() instead for production

ReactDOM.render(
  <StrictMode>
    <Provider {...{store}}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
