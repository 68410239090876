/*=============================================================================
 Timeline - infographics

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { cLo } from 'utils/util';
import TIMELINE_DATA from 'asset/test/timelineDataTest.json';

interface ITimelineItemProps {
  time?:  string;
  pos:    0 | 1 | 2 | 3;   // even: left, odd: right (for extension with various colorsets)
  title?: string;
};
const TimelineItem: React.FC<ITimelineItemProps> = ({ time, pos, title, children }) => {
  const clrSet = [
    { fg: "text-white",    bg: "bg-red-400" },
    { fg: "text-gray-800", bg: "bg-gray-400" },
    { fg: "text-white",    bg: "bg-blue-500" },
    { fg: "text-gray-100", bg: "bg-green-600" },
  ];
  return (
    <div
      {...cLo("mb-8 flex justify-between items-center w-full",
        (pos % 2 === 0) && "flex-row-reverse")}
    >
      <div className="order-1 w-5/12" />
      {time &&
      <div className="z-10 flex items-center order-1 bg-gray-800 shadow-xl w-12 h-12 rounded-full">
        <h1 className="mx-auto font-semibold text-base text-white">{time}</h1>
      </div>}
      <div {...cLo("order-1 bg-gray-400 rounded-lg shadow-xl w-5/12 px-6 py-4", clrSet[pos].bg)}>
        {title && <h3 {...cLo("mb-3 font-bold text-xl", clrSet[pos].fg)}>{title}</h3>}
        <p {...cLo("text-sm leading-snug tracking-wide", clrSet[pos].fg)}>{children}</p>
      </div>
    </div>
  );
}

interface ITimelineData extends ITimelineItemProps {
  id:      number;
  content: string;
};

export default function Timeline() {
  return (
    <div className="dark:bg-gray-500 bg-gray-200 mx-auto">
      <div className="relative wrap p-10 h-full">
        <div
          className="absolute border-l-2 border-opacity-80 border-gray-700 h-full border"
          style={{left: "50%"}}
        />
        {(TIMELINE_DATA as ITimelineData[]).map(td => {
          const { id, content, ...rest } = td;
          return <TimelineItem key={id} {...rest}>{content}</TimelineItem>;
        })}
      </div>
    </div>
  );
}
