/*=============================================================================
 reqQueue.ts - request Queue

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { errorFmt } from 'utils/util';

// class CacheRequests {  // cache for requested string ids (uid, room Id, etc...)
//   queue: string[] = [];
//   isReq = (id: string) => this.queue.includes(id);
//   addReq = (id: string, name?: string) => {
//     if (this.isReq(id)) {
//       console.log(`${name}:`, id, " already in queue");
//       return 0;
//     }
//     console.log(`${name}:`, id);
//     return this.queue.push(id);  // length
//   }
//   removeReq = (id: string) => this.queue = this.queue.filter(i => i !== id);
// };
/*  cache for requested string ids (uid, room Id, etc...)
 */
class CacheRequests {
  queue: Set<string>;
  constructor() {
    this.queue = new Set<string>();
  }
  isReq = (id: string) => this.queue.has(id);
  addReq = (id: string, name?: string) => {
    if (this.isReq(id)) {
      console.log(`${name}:`, id, " already in queue");
      return 0;
    }
    console.log(`${name}:`, id);
    return this.queue.add(id);  // Set object with added value
  }
  removeReq = (id: string) => this.queue.delete(id);
};

export const WARN_REQ_IN_QUEUE = errorFmt('WARN', "Request Cache", "Request already in queue");
export const CREQ = new CacheRequests();
