/*=============================================================================
 fetchData.ts - fetch data

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { CREQ, } from 'api/reqQueue';
import { fetchUser, isUserById, } from 'features/users/usersSlice';
import { fetchRoom, isRoomById, } from 'features/rooms/roomsSlice';
import type { IRoom } from 'models';
import type { TDispatch } from 'app/store';

/**
 * fetch Users
 */
export const fetchUsers = (dispatch: TDispatch, users: Undefinable<TUserID[]>) => {
  users?.forEach(uid => {
    if (!isUserById(uid) && !CREQ.isReq(uid))
      dispatch( fetchUser(uid) );
  });
}

/**
 * fetch Rooms with users
 */
export const fetchRooms = (dispatch: TDispatch, rooms: Undefinable<TRoomID[]>) => {
  rooms  // fetch all rooms including exited
    ?.filter((rId: TRoomID) => !isRoomById(rId) && !CREQ.isReq(rId))
    .forEach(async (rId: TRoomID) => {
      try {
        const resultAction = await dispatch( fetchRoom(rId) );
        const room = resultAction.payload as IRoom;
        fetchUsers(dispatch, room.xUsers
          ? [...room.users, ...room.xUsers]
          : room.users);
      } catch(error) {
        console.log(`Error fetching room ${rId}`, error);
      } finally {
        console.log(`Finally, all users in ${rId} fetched!`);
      }
    });
}
