/*=============================================================================
 DnDKanban.tsx - Drag n Drop for Kanban

 ---------------      ---------------      ---------------      ---------------
  Backlogs       --->  To do          --->  In progress    --->  Completed
 ---------------      ---------------      ---------------      ---------------

 - react-beautiful-dnd example:  [2021-02-23]
  1. Component    -> Hooks
  2. JSX          -> TypeScript
  3. inline style -> tailwindcss
 - The structure became so elegant thanks to the TypeScript!!! [2021-02-24]
 - reorder (in the same column) or move (inter-column) logic unified! [2021-02-24]
 - object vs array; obj[key] or arr.filter(a => a.id === ID)
 - When scrolled up and dragging, the drag animation appears at the scrolled up position.

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState } from 'react';
import {
  DragDropContext, DropResult, DraggableId,
  Droppable,  // container
  Draggable,  // items
} from 'react-beautiful-dnd';
import { cL, cLo, cCo } from 'utils/util';

interface IItem {  // draggable/dropppable item
  id:      DraggableId;
  content: React.ReactNode;
};

interface IBgColor {  // List background color in tailwindcss
  active:   TailwindColor<'bg'>;
  inactive: TailwindColor<'bg'>;
};
interface IItemColor extends IBgColor {  // Item color in tailwindcss
  border:   TailwindColor<'border'>;
};
interface IDraggableList {
  title:   string;
  dList:   IItem[];     // an array of draggable items in the column
  bgClr:   IBgColor;    // container
  itemClr: IItemColor;  // items
};
type TDraggableLists = Record<DraggableId, IDraggableList>;

const CardSample = ({ id, title }: { id: number, title: string }) => {
  const url = "#"
  return (
    <div className="flex flex-col p-2 max-w-sm mx-auto">
      <div className="flex justify-center items-center">
        <a className="px-2 py-1 bg-gray-600 text-xs text-green-100 rounded" href={url}>
          {`To Do Item #${id}`}
        </a>
      </div>
      <div className="mt-1 text-left">
        <a className="text-sm text-gray-700 font-medium" href={url}>{title}</a>
      </div>
      <div className="flex justify-between items-center mt-1">
        <div className="flex items-center">
          <img src={`https://randomuser.me/api/portraits/women/${id}.jpg`}
            className="w-8 h-8 object-cover rounded-full" alt="avatar" />
          <a className="text-gray-700 text-xs mx-3" href={url}>Stuart K.</a>
        </div>
        <span className="font-light text-xs text-gray-600">Feb 25, 2021</span>
      </div>
    </div>
  );
}

// fake data generator
// Array.from() creates Arrays from array-like objects (with a lenth property and indexed elements)
let itemOffset = 0;
const getItems = (
  count:  number
): IItem[] => {
  const titles = [
    "Digital Library Organizer",
    "Meme Generator",
    "P2P Web Tutoring",
    "P2P Language Exchange",
    "P2P File Sharing",
    "Ethnic Group Community Builder",
    "SpacetimeQ Search",
    "DeFi - Decentralized Finance",
    "Contents Discovery Platform",

    "Zettelkasten - Note-taking",
    "Skillshare with Data Visualization",
    "Planetarium",
    "Horoscope",
    "Market Watch",

    "Basic Messaging Platform / WebChat",
    "Shopping on BMP",
    "P2P Video Communication",
    "Kanban Schedule Manager",
    "Calendar",
    "World News",
    "SpacetimeQ 3D Earth",
    "Music/Video Playlist Manager",
    "vLogging",
    "Web Video Compositor",

    "discord (chat community)",
    "slack (business communication)",
    "Notion (note taking)",
    "Atlassian (Kanban / Project Management)",
    "Shopify (eCommerce) Storefront API",
    "StackOverflow (Q&A)",
    "Zoom (video conferencing)",
    "Crowdworks (matching jobs)",
    "Tidio (Live Caht for Shopify)",
    "IPFS / Filecoin",
  ];
  const offset = itemOffset;
  itemOffset += count;
  return Array.from({ length: count }, (_v, k) => k)  // generate a sequence of numbers
    .map(k => {
      const n = k + offset;
      return ({
        id: `item-${n}`,
        content: <CardSample id={n} title={titles[n % (titles.length)]} />
      });
    }
  );
}
/*
function getRandomColor()  {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
            <SvgIcon fill={getRandomColor()} Path="bell_noti" />
            <span className="ml-1 text-sm text-purple-600">{`To Do #${n}`}</span>
 */

const InitDraggableLists: TDraggableLists = {  // initial data
  droplist0: {
    title: "Backlogs",
    dList: getItems(9),
    bgClr:   { active:   "bg-green-200",
               inactive: "bg-gray-400" },
    itemClr: { border:   "border-green-400",
               active:   "bg-indigo-300",
               inactive: "bg-gray-200" }
  },
  droplist1: {
    title: "To Do",
    dList: getItems(5),
    bgClr:   { active:   "bg-blue-200",
               inactive: "bg-gray-400" },
    itemClr: { border:   "border-blue-400",
               active:   "bg-green-300",
               inactive: "bg-yellow-100" }
  },
  droplist2: {
    title: "In Progress",
    dList: getItems(10),
    bgClr:   { active:   "bg-red-200",
               inactive: "bg-gray-400" },
    itemClr: { border:   "border-red-400",
               active:   "bg-pink-300",
               inactive: "bg-yellow-200" }
  },
  droplist3: {
    title: "Completed",
    dList: getItems(3),
    bgClr:   { active:   "bg-purple-200",
               inactive: "bg-gray-400" },
    itemClr: { border:   "border-purple-400",
               active:   "bg-rose-300",
               inactive: "bg-yellow-300" }
  },
};

function DnDKanbanDemo() {
  const [DLs, setDLs] = useState<TDraggableLists>(InitDraggableLists);
  // const getDL = (dId: DraggableId) => DLs.filter(dl => (dl.dId === dId));

  const onDragEnd = (result: DropResult) => {
    //-------------------------------------------------------------------------
    const { source, destination } = result;  // source, destination: DraggableLocation
    const srcId  = source.droppableId;
    const srcIdx = source.index;
    //-------------------------------------------------------------------------
    if (!destination)  // dropped outside the list, destination is possibly null
      return;
    const destId  = destination?.droppableId;
    const destIdx = destination.index;

    const sameDroppable = srcId === destId;
    if (sameDroppable && srcIdx === destIdx)  // no reorder
      return;

    // in the same column -> just reorder
    // inter-columns -> move items
    const srcCopy  = [...DLs[srcId].dList];  // Array.from(), shallow copy
    const destCopy = sameDroppable
      ? srcCopy               // same column: just reference it
      : [...DLs[destId].dList];  // other column: shallow copy
    const [removed] = srcCopy.splice(srcIdx, 1);  // remove from source
    destCopy.splice(destIdx, 0, removed);         // move to destination
    setDLs({
      ...DLs,   // keep the state of the irrelevant column(s)
      ...{[srcId]: { ...DLs[srcId], dList: srcCopy },
          ...(!sameDroppable && { [destId]: {...DLs[destId], dList: destCopy } })
      }  // update any changed column items
    });
  }  // onDragEnd

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {Object.keys(DLs).map((key) => <DraggableList key={key} dId={key} {...DLs[key]} />)}
    </DragDropContext>
  );
}

const DraggableList = ({dId, title, dList, bgClr, itemClr}:
  { dId: DraggableId } & IDraggableList  // List in column/container
) => {
  return (
    <div {...cLo("bg-opacity-10 rounded-xl", bgClr.active)}>
      <h1 className="py-1 rounded-t-lg text-center font-mono bg-black text-white">
        {title}<span className="text-red-300">({dList.length})</span>
      </h1>
      <Droppable droppableId={dId}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...cCo("select-none p-1 rounded-b-lg border-4 border-double border-black",
              snapshot.isDraggingOver, bgClr.active, bgClr.inactive)}
            style={{
              minHeight: "25%",
              minWidth:  "14rem",
              maxWidth:  "14rem"
            }}
          >
            {dList.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    {...cCo(cL("flex text-center rounded-lg border shadow-md mb-2", itemClr.border),
                      snapshot.isDragging, itemClr.active, itemClr.inactive)}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default function DnDKanban() {
  return (
    <>
      <h1 className="text-center dark:text-blue-300 dark:bg-gray-800 text-red-700 bg-yellow-200">
        Kanban Board
      </h1>
      <div className="flex justify-around mt-4">
        <DnDKanbanDemo />
      </div>
    </>
  );
}
