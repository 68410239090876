/*=============================================================================
 MathView.tsx - Mathematics Equations Viewer using KaTeX

 - KaTeX Options: https://katex.org/docs/options.html

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState } from 'react';
import { cL, cCo, } from 'utils/util';
import { RegEx_Cont } from 'utils/RegEx';
import TeX from 'utils/TeX';

export const mathCfg = {
  delimeter: '$$',
  replacer:  '',  // to be replaced with the original equation expression
};

interface IMathView extends IClassNameObj {
  aMath: string[];  // array of KaTeX math expressions (enclosed with delimeters)
};
export const MathView = ({ aMath, className }: IMathView) => {
  const [showF, setShowF] = useState(false);  // show formula and zoom up
  const EqDelimeter = () =>
    <span className="text-pink-300">{mathCfg.delimeter}</span>;
  return (
    <ul {...cCo(cL(className, "p-2 rounded-lg transition duration-700 ease-in-out cursor-pointer",
        "dark:hover:text-white dark:hover:bg-black", "hover:text-white hover:bg-gray-800"),
        showF, "text-xl", "text-base")}
    >
      {aMath.map((m, i) => {
        // const aM = m.match(RegEx_Cont.math_eq);  // Safari compatibility issue
        const math = m.replaceAll(mathCfg.delimeter, '');  // remove delimeters
        if (!math)
          return null;
        return (
          <li key={i}
            onClick={() => setShowF(!showF)}
          >
            <TeX {...{math}}
              block={RegEx_Cont.math_block.test(math)}
            />
            {showF &&
              <div className="pt-2 text-3xs font-mono">
                <EqDelimeter />{math}<EqDelimeter />
              </div>}
          </li>);
      })}
    </ul>
  );
}
