/*=============================================================================
 RootPage.tsx - Top page

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { cLo, } from 'utils/util';
import { SpacetimeQ, Copyright } from 'ui/ui';

export default function RootPage() {
  return (
    <>
      <h1 className="mt-8 font-mono text-center text-lg dark:text-white text-black">
        <SpacetimeQ /> Messaging Based Platform
      </h1>
      <ol {...cLo("ml-6 mt-8 text-base font-mono dark:text-white text-black leading-10")}>
        <li>Homebase - Markdown</li>
        <li>Web Chat - Link & Embedding</li>
        <li>Closed Community - Collaboration</li>
      </ol>
      <Copyright {...cLo("mt-2 text-xs text-center")} />
    </>
  );
}
