/*=============================================================================
 usersAPI.ts - users database APIs abstraction layer

 - Current: Encapsulation of Firebase Cloud Firestore data access APIs
 - Any database dependent API code should come under api/ directory.
   Other application specific codes need to be separated from the backend API codes.
 - Let the work of transporting from Firebase Cloud Firestore to MongoDB be easy.

 - https://stackoverflow.com/questions/32536049/do-i-need-to-return-after-early-resolve-reject/32536083
 - https://firebase.google.com/docs/firestore/manage-data/add-data

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
import firebase from './firebase';
import type { IUserCreate, IUser, IUserEx, IUserUp, IAddOrRemove, } from 'models';
import { fsPath, serverTS, serializeTime, } from './apiCommon';
import { errorFmt, } from 'utils/util';
import { getUserById, } from 'features/users/usersSlice';
import type { Update } from '@reduxjs/toolkit'
import { CREQ, WARN_REQ_IN_QUEUE } from './reqQueue';

//-----------------------------------------------------------------------------
// User
//-----------------------------------------------------------------------------
/**
 * fetch user: wrapper of the firebase API
 */
export const fetchUserAPI = (uid: string): Promise<IUserEx> =>
  new Promise<IUserEx>(async (resolve, reject) => {
    if (!CREQ.addReq(uid, "fetchUserAPI")) {
      reject(WARN_REQ_IN_QUEUE);
      return;
    }
    try {
      //-----------------------------------------------------------------------
      // user document, read
      //-----------------------------------------------------------------------
      const doc = await firebase.firestore()
        .doc(fsPath.user(uid))
        .get();
      //-----------------------------------------------------------------------
      if (doc.exists) {
        const data = doc.data(); // need to exclude non-serializables such as createdAt
        // console.log(doc.id, " => ", data);
        if (data) {
          resolve({  // order matters: cratedAt should be put last not to be overwritten
            uid,
            ...(data as IUserCreate),
            ...(data.createdAt && { createdAt: serializeTime(data.createdAt) }),
            ...(data.signInAt  && { signInAt:  serializeTime(data.signInAt)  }),
          });
        } else {
          reject(errorFmt('ERROR', "fetchUserAPI", "Null data"));
        }
      } else {
        reject(errorFmt('ERROR', "fetchUserAPI", `${fsPath.users}/${uid} not found!`));
      }
    } catch (error) {
      reject(error);
    } finally {
      CREQ.removeReq(uid);
    }
  });

/**
 * create app user with the authenticated firebase User
 */
export const createUserAPI = (user: IUser): Promise<IUserEx> => {
  const { uid, ...userProps } = user;
  const data: IUserCreate = {
    ...userProps,
    group:   "group",
    city:    "city",
    profile: "profile",
    backURL: "osanbashi.jpg",
  };
  return new Promise<IUserEx>(async (resolve, reject) => {
    if (!CREQ.addReq(uid, "createUserAPI")) {
      reject(WARN_REQ_IN_QUEUE);
      return;
    }
    try {
      //-----------------------------------------------------------------------
      // user document, create
      //-----------------------------------------------------------------------
      await firebase.firestore()
        .doc(fsPath.user(uid))
        .set({
          ...serverTS('createdAt'),
          ...serverTS('signInAt'),
          ...data
          }, { merge: true });  // Promise<void>
      //-----------------------------------------------------------------------
      console.log("User successfully written:", uid);
      resolve({ uid, ...data });
    } catch (error) {
      reject(error);
    } finally {
      CREQ.removeReq(uid);
    }
  });
}

/**
 * update user with the given user data
 *  @param user IUserUp
 *  @returns Update<IUserEx>
 */
export const updateUserAPI = (user: IUserUp): Promise< Update<IUserEx> > => {
  const { uid, ...data } = user;
  return new Promise< Update<IUserEx> >(async (resolve, reject) => {
    try {
      //-----------------------------------------------------------------------
      // user document, update
      //-----------------------------------------------------------------------
      await firebase.firestore()
        .doc(fsPath.user(uid))
        .update(data);  // Promise<void>
      //-----------------------------------------------------------------------
      console.log("user successfully updated:", uid);
      resolve({ id: uid, changes: { ...data } });
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * update contacts list
 */
export interface IUpdateUserContactsProps extends IAddOrRemove {
  uid: TUserID;  // user id of which contacts will be updated
  id:  TUserID;  // user id to add or remove
};
/**
 * update contacts list of *uid* by *add*ing or removing *id*
 */
export const updateUserContactsAPI = ({ uid, id, add }: IUpdateUserContactsProps)
: Promise< Update<IUserEx> > => {
  const user = getUserById(uid);  // cannot use a hook here
  return new Promise< Update<IUserEx> >(async (resolve, reject) => {
    if (!user) {
      reject(errorFmt('ERROR', "No User", "No Current User!"));
      return;
    }
    try {
      //-----------------------------------------------------------------------
      // user document, update
      //-----------------------------------------------------------------------
      await firebase.firestore()
        .doc(fsPath.user(user.uid))
        .update({
          aContacts: add
          ? firebase.firestore.FieldValue.arrayUnion(id)
          : firebase.firestore.FieldValue.arrayRemove(id)
        });
      //-----------------------------------------------------------------------
      console.log("Contacts successfully updated");
      let contacts: TUserID[] = user.contacts ? [...user.contacts] : [];
      if (add)
        contacts.push(id);
      else
        contacts = contacts.filter(cId => cId !== id);
      resolve({ id: user.uid, changes: { contacts } });
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * update rooms list
 */
export interface IUpdateUserRoomsProps extends IAddOrRemove {
  uid: TUserID;  // user id of which its rooms will be updated
  id:  TRoomID;  // room id to add or remove
};
/**
 * update rooms list of *uid* by *add*ing or removing *id*
 */
export const updateUserRoomsAPI = ({ uid, id, add }: IUpdateUserRoomsProps)
: Promise< Update<IUserEx> > => {
  const user = getUserById(uid);  // cannot use a hook here
  return new Promise< Update<IUserEx> >(async (resolve, reject) => {
    if (!user) {
      reject(errorFmt('ERROR', "No User", "No Current User!"));
      return;
    }
    try {
      //-----------------------------------------------------------------------
      // user document, update
      //-----------------------------------------------------------------------
      await firebase.firestore()
        .doc(fsPath.user(user.uid))
        .update(add
          ? {
              rooms:  firebase.firestore.FieldValue.arrayUnion(id),
              xRooms: firebase.firestore.FieldValue.arrayRemove(id),  // if exist
            }
          : {
              rooms:  firebase.firestore.FieldValue.arrayRemove(id),
              xRooms: firebase.firestore.FieldValue.arrayUnion(id),
            });
      //-----------------------------------------------------------------------
      console.log("Rooms successfully updated");
      let rooms:  TRoomID[] = user.rooms  ? [...user.rooms]  : [];
      let xRooms: TRoomID[] = user.xRooms ? [...user.xRooms] : [];
      if (add) {
        rooms.push(id);
        xRooms = xRooms.filter(rId => rId !== id);
      } else {
        rooms = rooms.filter(rId => rId !== id);
        xRooms.push(id);
      }
      resolve({ id: user.uid, changes: { rooms, xRooms } });
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * query user
 * Without using Redux, directly check the existence of data in DB
 * TODO: like search
 */
export const queryUserAPI = (email: string): Promise<IUserEx> =>
  new Promise<IUserEx>(async (resolve, reject) => {
    if (!CREQ.addReq(email, "queryUserAPI")) {
      reject(WARN_REQ_IN_QUEUE);
      return;
    }
    try {
      //-----------------------------------------------------------------------
      // users collection
      //-----------------------------------------------------------------------
      const querySnapshot = await firebase.firestore()
        .collection(fsPath.users)
        .where("email", "==", email)
        .get();
      //-----------------------------------------------------------------------
      console.log("querySnapshot size:", querySnapshot.size);
      if (querySnapshot.empty) {
        reject(errorFmt('ERROR', "queryUserAPI", "Null data"));
      } else {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          const data = doc.data(); // need to exclude non-serializables such as createdAt
          resolve({  // order matters: cratedAt should be put last not to be overwritten
            uid: doc.id,
            ...(data as IUserCreate),
            ...(data.createdAt && { createdAt: serializeTime(data.createdAt) }),
            ...(data.signInAt  && { signInAt:  serializeTime(data.signInAt)  }),
          });
        });
      }
    } catch (error) {
      reject(error);
    } finally {
      CREQ.removeReq(email);
    }
  });
