/*=============================================================================
 Open1to1Room.tsx - Open a 1-to-1 channel

 0. Make a list of 1-to-1 channels on load
 1. Check if the room with the user already exist.
 2. Enter the room if it exists, otherwise create a new room with default options.
    - title: 1-to-1 with {other user's name}
    - backURL: undefined

  Get the list of rooms[rId].users.length === 2 where user[0 or 1] === uid.

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useRef, } from 'react';
// import { useAppDispatch } from 'app/store';
// import { unwrapResult } from '@reduxjs/toolkit';
import { cLo, } from 'utils/util';
import { SvgIcon, } from 'utils/svg';
// import { msgEnterRoom, msgExitRoom, } from 'features/msgs/msgsSlice';
import { useButton }  from '@react-aria/button';

export const Open1to1Room = ({ uids }: { uids: TUserID[]; }) => {
  const openBtnRef = useRef<HTMLButtonElement>(null);
  const { buttonProps: openBtnProps  } = useButton( {
    onPress: () => alert(`Working... 1to1 channel ${uids[0]}, ${uids[1]}`) },
    openBtnRef
  );

  return (
    <button
      ref={openBtnRef}
      {...openBtnProps}
      {...cLo("flex flex-row items-center focus:outline-none cursor-pointer opacity-20")}
    >
      <SvgIcon Path="bubble_chat" strokeWidth={1} />
      <span className="ml-1">1:1 Channel</span>
    </button>
  );
}
