/*=============================================================================
 LeftSideDrawer.tsx - Left SideDrawer

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { cLo, } from 'utils/util';
import { CloseButton } from 'ui/ui';
import SideDrawer from 'ui/SideDrawer';
import type { ISideDrawerProps } from 'ui/SideDrawer';
import { TabsList } from 'ui/TabsList';
// Contents in the Tab Pane
import { PaneContacts } from './PaneUsers';
import PaneDetails  from './PaneDetails';
import { CalToday } from 'features/cal/CalToday';
import { SvgIcon, } from 'utils/svg';
import type { TPathDataKey, } from 'utils/svg';

/**
 * Left SideDrawer
 * <CloseButton> component can come anywhere since it's on absolute position
 */
const LeftSideDrawer = ({
  vertical,
  refPush,
  disabled,
}: Pick<ISideDrawerProps, 'vertical'|'refPush'|'disabled'>) => {
  const twWidth = vertical ? 'w-full' : "w-80";  // width should be fixed for each tab content
  const SvgTitle: React.FC<{ Path: TPathDataKey }> = ({ Path, children }) =>
    <div {...cLo("flex flex-row items-center pt-1")}>
      <SvgIcon {...cLo("w-4 h-4")} {...{Path}} strokeWidth={0} />
      <span {...cLo("ml-1 text-2xs")}>{children}</span>
    </div>;

  return (
    <SideDrawer
      {...{vertical, refPush, disabled}}
      tooltip="Contacts and Members"
    >
      {closeCB =>
        <TabsList items={[
          {
            title:   <SvgTitle Path="users_group">Contacts</SvgTitle>,
            content: <PaneContacts {...cLo(twWidth)} />
          },
          {
            title:   <SvgTitle Path="user_frame">Members</SvgTitle>,
            content: <PaneDetails {...cLo(twWidth)} />
          },
          {
            title:   <SvgTitle Path="note_blog">Work</SvgTitle>,
            content: <CalToday {...cLo(twWidth)} />
          },
        ]}
        >
          <CloseButton
            {...cLo("absolute top-0 right-0 mt-1.5 mr-1 h-4 w-4 border")}
            onPress={() => closeCB()}
          />
        </TabsList>
      }
    </SideDrawer>
  );
}

export default LeftSideDrawer;
