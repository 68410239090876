/*=============================================================================
 Calendar/index.tsx - Calendar

 ---------------
 Calendar Layout                   - 1. Today
 ---------------                   - 2. Weekly/Monthly
 <-> Sidebar          ^folable     - 3. Quarterly/Yearly
 -----------------------------     - 4. Life Spectrum
 Today     | Celestial View        - 5. Celestial
 World Time|------------------     - 6. Weather / AQI
 --------- | Weekly/Monthly        - 7. Market
 Life      |                       - 8. World News
 Spectrum  | Quarterly/Yearly      - 9. Horoscope
 -----------------------------     - 10. Schedules
 ↑navigational help                - 11. Gamified Todos / Quests

 - Date should be updated when the day changes

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useState, useRef, } from 'react';
import { cLo, cLoIf, cS, showIf, } from 'utils/util';
import { dowFirstDayInYM, daysInYM, moonAgeYMD, emojiMoon, sekkiYmd,
  monthsToYM, todayMonths, oYMDym, IYMD, ymdToISOString,
  DW7, M12, SD24, SD72, SD72_24, weekName, sekkiName, } from 'utils/calendar';
import type { TSekkiDivision } from 'utils/calendar';
import { ButtonRotate, Copyright } from 'ui/ui';
import { useWindowSize } from 'utils/windows';
import SideDrawer from 'ui/SideDrawer';
import { CalToday } from './CalToday';
import { TooltipSpan } from 'ui/Tooltip';

// tailwind colorset
export const twDow = (i: number) =>
  (i === 0)
  ? "dark:text-red-400 text-red-500 font-bold"   // Sunday
  : (i === 6)
    ? "dark:text-blue-400 text-blue-500 font-bold" // Saturday
    : "dark:text-white text-black";

interface ICalendarOptionsProps {
  initMonths: number;
  months:     number;
  skDiv:      TSekkiDivision;
};
interface ICalendarSetOptionsProps extends ICalendarOptionsProps {
  // setMonths: (m: number)        => void;  // callbacks
  // setSkDiv: (d: TSekkiDivision) => void;
  setMonths: React.Dispatch< React.SetStateAction<number> >;
  setSkDiv:  React.Dispatch< React.SetStateAction<TSekkiDivision> >;
};

/**
 * ----------------------------------------------------
 * Calendar main
 *
 * Slide                        under sm (responsive)
 * -----------------------  ->   Left
 * Today  |  Sekki              --------
 * ------ |  Month <-o->         Right
 * World  |   table
 * Time   |--------------
 * ------ |  Year
 * ----------------------------------------------------
 */
export default function Calendar({
  className = "h-screen"
}: IClassNameObj) {
  const refPush = useRef<HTMLDivElement>(null);  // allow <Sidebar> to handle <div> with refPush
  const { w: maxWidth, } = useWindowSize();
  const vertical = maxWidth <= 360;  //> Sidebar vertical move for mobile
  const initMonths = todayMonths();
  // Calenadar view options
  const [months, setMonths] = useState(initMonths);  // yyyy/mm in serial months
  const [skDiv,  setSkDiv]  = useState<TSekkiDivision>(SD72);
  return (
    <div {...cLo(className, "overflow-y-scroll dark:bg-gray-800 bg-gray-200")}>
      <SideDrawer {...{vertical, refPush}} tooltip="Today">
        {_handleCallback => <CalToday />}
      </SideDrawer>
      <div ref={refPush}
        {...cLo("flex items-start justify-center w-full h-screen p-1",
          "Drawer_transition_padding")}
      >
        <div className="px-2">
          <CalendarControl {...{initMonths, months, skDiv, setMonths, setSkDiv}} />
          <table className="table-fixed w-full dark:bg-gray-800 bg-gray-50 mb-2">
            <CalTableHead />
            <CalTableMonth {...{initMonths, months, skDiv}} />
          </table>
          <Copyright />
        </div>
      </div>
    </div>
  );
}

/**
 * Monthly Calendar Head part (week names)
 */
const CalTableHead = () => {
  const week = Array.from({ length: DW7 }, (_, i) => weekName(i, 'en'));
  return (
    <thead>
      <tr>
        {week.map((name, i) =>
          <th key={i}
            {...cLo("p-2 font-serif text-center text-sm border",
              "dark:border-gray-600 border-gray-300",
              "dark:bg-gray-600 bg-gray-200 TextShadow")}
          >
            <span {...cLo("hidden lg:block", twDow(i))}>{name}</span>
            <span {...cLo("block lg:hidden", twDow(i))}>{name.substr(0,3)}</span>
          </th>
        )}
      </tr>
    </thead>
  );
}

// ----------------------------------------------------------------------------
/**
 * Calendar month table
 * - dynamically builds each month dates arrays for 4 to 6=trunc((31+6)/7) rows
 */
const CalTableMonth = ({ initMonths, months, skDiv }: ICalendarOptionsProps) => {
  const cm = monthsToYM(months);                // current month
  const cmDays = daysInYM(cm);                  // current month days
  const dow = dowFirstDayInYM(cm);              // day of week(0..6 sun..sat) of the starting day
  const rows = Math.ceil((dow + cmDays) / DW7); // Total number of rows to contain the month
  const pm = monthsToYM(months - 1);            // previous month
  const pmDays = daysInYM(pm);                  // previous month days
  // -------------------------------------------------------------------------
  // Dynamically generate the month's table with rows of weeks
  // -------------------------------------------------------------------------
  const weeks: IYMD[][] = new Array(rows);      // Get an array of weeks data.
  // -------------------------------------------------------------------------
  let c = (dow === 0)                           // cursor to loop through days in the month
    ? oYMDym(cm, 1)                             // the month starts from day 1, no need prev month
    : oYMDym(pm, pmDays - dow + 1);             // need previous month dates to fill the gap
  const WK_LEN = { length: DW7 };
  // the 1st week ------------------------------------------------------------
  weeks[0] = Array.from(WK_LEN, () => {
    if (c.date === pmDays + 1)                  // done with previous month
      c = oYMDym(cm, 1);                        // reset cursor to the current month's first day
    return oYMDym(c, c.date++);                 // advance one day
  });
  // the middle weeks --------------------------------------------------------
  let r = 1;
  while (r++ < rows - 1) {
    // eslint-disable-next-line no-loop-func
    weeks[r] = Array.from(WK_LEN, () => oYMDym(c, c.date++));  // advance one day
  }
  // the last week -----------------------------------------------------------
  weeks[r] = Array.from(WK_LEN, () => {         // r === rows - 1
    if (c.date === cmDays + 1)                  // don with current month
      c = oYMDym(monthsToYM(months + 1), 1);    // reset cursor to the next month's first day
    return oYMDym(c, c.date++);                 // advance one day
  });
  // -------------------------------------------------------------------------
  return (
    <tbody>
      {weeks.map((week, row) =>
      <tr key={row} className="text-center">
        {week.map((ymd, dow) =>
          <DateCell
            key={dow}
            ymd={ymd}
            {...{dow}}
            isThisMonth={months === initMonths}
            curMonth={cm.month}
          >
            <Sekki {...{ymd}} div={skDiv} />
          </DateCell>
        )}
      </tr>
      )}
    </tbody>
  );
}

// ----------------------------------------------------------------------------
interface IDateCellProps {
  ymd:         IYMD;
  dow:         number;   // day of week
  isThisMonth: boolean;  // Does this month contain today?
  curMonth:    number;   // current month (not in prev nor next month)
};
/**
 * Each Date cell in a month
 */
const DateCell: React.FC<IDateCellProps> = ({ ymd, dow, isThisMonth, curMonth, children }) => {
  const isCM = ymd.month === curMonth;
  const isToday = isThisMonth && isCM && new Date().getDate() === ymd.date;
  const handleDateClick = (d: IYMD) => {
    console.log(d);
  }
  return (
    <td {...cLo("overflow-auto border cursor-pointer",
      isCM
        ? isToday && "dark:bg-gray-600 bg-yellow-100"
        : "dark:bg-gray-700 bg-gray-200 opacity-40",
      "dark:border-gray-600 border-gray-300",
      "transition duration-500 ease dark:hover:bg-gray-500 hover:bg-gray-100")}
    >
      <div {...cLo("flex flex-col h-24 sm:h-32 md:h-40 mx-auto overflow-hidden")}
        onClick={() => handleDateClick(ymd)}
      >
        <div className="w-full top pb-1 font-serif border-b dark:border-gray-700 border-gray-300">
          <time
            {...cLo(twDow(dow), "text-lg lg:text-2xl", isCM && "TextShadow")}
            dateTime={ymdToISOString(ymd)}
          >
            {ymd.date}
          </time>
          <EmojiMoonPhase age={moonAgeYMD(ymd)} force={isToday} />
        </div>
        {children}
      </div>
    </td>
  );
}

/**
 * Emoji Moon Phase
 */
const EmojiMoonPhase = ({ age, force }: { age: number; force: boolean }) => {
  const emoji = emojiMoon(age, force ? 1 : undefined);  // tolerance: undefinded - use default
  if (!emoji)
    return null;
  return (
    <span {...cLo("relative md:pl-2 sm:pl-1 text-3xs")}>
      <span className="md:pr-2 md:text-lg">
        {emoji}
      </span>
      <span className="absolute md:text-xs dark:text-yellow-300 text-gray-500">
        {age.toFixed(1)}
      </span>
    </span>
  );
}

// ----------------------------------------------------------------------------
/**
 * 二十四節気、七十二候
 */
const Sekki = ({ ymd, div }: { ymd: IYMD; div: TSekkiDivision }) => {
  const sk = sekkiYmd(ymd, div);
  if (!sk)
    return null;
  const colors = [
    { s24: "font-bold bg-red-600", s72: "bg-red-200" },
    { s24: "bg-blue-600",          s72: "bg-blue-200" },
    { s24: "bg-green-600",         s72: "bg-green-200" }
  ];
  // const clrStyle = { background: `hsla(${sk.hue},100%,50%,1)` };  // from avg temperature
  const id24 = (div === SD24) ? sk.id : Math.trunc((sk.id - 1) / (SD72_24)) + 1;
  const cId = (id24 - 1) % 3;  // color index for three groups in 24 sekki

  // Container block should have "relative"
  const TagN: React.FC<IClassNameObj> = ({ className: cn, children }) =>
    <div {...cLo("absolute text-3xs lg:text-xs left-0 top-0 shadow-md hidden sm:block", cn)}>
      {children}
    </div>;

  return (
    <div {...cLo("mt-0.5 font-serif")}>
      <div {...cLoIf(sk.s24, "relative text-sm sm:text-lg text-white TextShadow truncate",
        div === SD24 ? "md:rounded-lg shadow-md" : "md:rounded-t-lg", colors[cId].s24)}
      >
        <TagN className="p-1 bg-gray-700 md:rounded-tl-md rounded-br-lg">{id24}</TagN>
        {sk.s24}
      </div>
      <div {...cLoIf(!sk.s24, "text-lg text-white TextShadow md:rounded-t-lg w-full h-2",
        colors[cId].s24)}
      />
      <div className={showIf(sk.s72)}>
        <div {...cLo("relative sm:pl-3 text-xs sm:text-lg text-black md:rounded-b-lg",
            "shadow-md truncate", colors[cId].s72)}
        >
          <TagN className="p-0.5 bg-gray-200 rounded-br-lg">{sk.id}</TagN>
          <ruby>
            {sk.s72?.name}
            <rt {...cLo("text-3xs text-blue-800 hidden lg:block")}>
              {sk.s72?.yomi}
            </rt>
          </ruby>
        </div>
        <div {...cLo("m-1 text-3xs xl:text-xs dark:text-gray-200 text-gray-600 text-left truncate")}>
          {sk.s72?.full}
        </div>
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------------
/**
 * Controller: to navigate monthly
 */
const CalendarControl = ({
  initMonths, months, skDiv, setMonths, setSkDiv
}: ICalendarSetOptionsProps) => {
  const { year, month } = monthsToYM(months);
  const dM = months - initMonths;   // difference in total months
  const dy = Math.trunc(dM / M12);  // year difference
  const dm = dM % M12;              // month differnce
  const monthStr = () => {
    const date = new Date(year, month, 1);
    const M   = date.toLocaleString('default', { month: 'short' });
    const jpy = date.toLocaleString('ja-JP-u-ca-japanese', { era: 'short', year: 'numeric' });
    return `${M} ${year} [${jpy}]`;
  }
  const fillOn = (cond: boolean) => cond ? ({ fill: "pink" }) : undefined;
  return (
    <div {...cLo("flex justify-between p-1 mt-2 rounded-t-xl",
        "dark:text-gray-900 dark:bg-gray-200 text-gray-50 bg-gray-800 min-w-[300px]")}
    >
      <div {...cLo("ml-1 text-sm md:text-xl font-serif")}>
        {monthStr()}
      </div>
      <div className="flex items-center font-serif">
        <span className="pr-2 text-xs sm:text-sm dark:text-blue-500 text-blue-200">
          {!!dM
            ? cS(dM > 0 && '+',
                !!dy && `${dy} yr `,
                !!dm && `${!!dy ? Math.abs(dm) : dm} mo`)
            : <span className="p-1.5 bg-gray-700 rounded-full">✨</span>}
        </span>
        <TooltipSpan tip={sekkiName(skDiv)}>
          <button
            {...cLo("mr-1 sm:mr-2 px-2 text-xs sm:text-sm bg-gray-600 text-yellow-300 rounded-full",
              "font-bold focus:outline-none hover:text-red-400")}
            onClick={(_e) => setSkDiv(skDiv === SD24 ? SD72 : SD24)}
          >
            {skDiv === SD24 ? "24" : "72"}
          </button>
        </TooltipSpan>
        <ButtonRotate Path="fast_rewind"
                      {...fillOn(dM <= -M12)}            onClick={(_e) => setMonths(months - M12)} />
        <ButtonRotate {...fillOn(dM <= -1 && dM > -M12)} onClick={(_e) => setMonths(months - 1)} />
        <ButtonRotate Path="note_calendar"
                      {...fillOn(dM === 0)}              onClick={(_e) => setMonths(initMonths)} />
        <ButtonRotate dir='R'
                      {...fillOn(dM >= 1 && dM < M12)}   onClick={(_e) => setMonths(months + 1)} />
        <ButtonRotate Path="fast_rewind" dir='R'
                      {...fillOn(dM >= M12)}             onClick={(_e) => setMonths(months + M12)} />
      </div>
    </div>
  );
}
