/*=============================================================================
 chat/Chat.tsx - Chat Main Window

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useState, forwardRef, } from 'react';
import { cL, cLo } from 'utils/util';
import { SvgIconFull } from 'utils/svg';

interface IChatInputControlProps {
  onSubmit:    (text: string) => void;
  onFileClick: () => void;  // callback to be called when Picture button pressed
};
/**
 * Chat Input Control
 * For Japanese IME input we need handleCompositionStart/End
 */
const ChatInputControl =
  forwardRef<HTMLDivElement, React.PropsWithChildren< IChatInputControlProps > >(
  ({
    onSubmit,     // to send text
    onFileClick,  // to open DnDWrapper's FileOpen UI
    children      // left icons if added
  }, ref) =>
{
  //console.log("ChatInputControl");
  // input on focus or out of focus
  const [noCompo, setNoCompo] = useState(true);  // not in IME composition mode
  const [hasText, setHasText] = useState(false);

  // text input editor DOM element, to get textContent and clear
  const btnCls = cL("flex flex-shrink-0 block w-6 h-6 mx-1 focus:outline-none",
    "dark:text-gray-300 dark:hover:text-gray-600",
    "text-gray-600 hover:text-black");
  // To check if in IME mode
  const handleCompositionStart = () => {
    // console.log("compositionStart");
    setNoCompo(false);
  }
  const handleCompositionEnd = () => {
    // console.log("compositionEnd");
    setNoCompo(true);
  }
  const onClickEmoticon = () => {
    console.log("Emoticon");
  }
  const _sendText = () => {
    const node = (ref as React.MutableRefObject<HTMLDivElement>).current;
    const txt = node?.textContent;
    if (txt && txt.length > 0) {
      onSubmit(txt);
      node!.innerHTML = ''; // clear current input
      setHasText(false);
    }
  }
  const submitText: TKeyboardInputHandler = (ev) => {
    ev.preventDefault();
    _sendText();
  }
  const handleKeyDown: TKeyboardInputHandler = (ev) => {
    if ((noCompo && (ev.key === 'Enter')) &&
      !ev.shiftKey)  // Shift + ENTER will not end typing, next line
      return submitText(ev);
  }
  const handleKeyUp: TKeyboardInputHandler = (ev) => {
    const txt = (ev.target as HTMLInputElement).innerHTML;  // Type Assertion
    setHasText(txt.length > 0 && txt !== '\n');
  }

  return (
    <div {...cLo("flex-none")}>
      <div {...cLo("flex flex-row items-center p-2 border-t",
        "dark:bg-gray-800 dark:border-gray-600 bg-gray-300 border-gray-400")}
      >
        {children}
        <form className="relative flex-grow">
          <div {...{ref}}
            {...cLo("w-full py-2 pl-3 pr-10 border rounded-md",
              "dark:text-gray-200 dark:bg-gray-800 dark:border-gray-700",
              "dark:focus:border-gray-400 dark:focus:bg-gray-900",
              "text-black bg-gray-200 border-gray-300",
              "focus:border-gray-500 focus:bg-gray-50",
              "focus:outline-none focus:shadow-md transition duration-100 ease-in")}
            tabIndex={0}
            contentEditable={true}
            placeholder="Type in..."
            onCompositionStart={handleCompositionStart}
            onCompositionEnd={handleCompositionEnd}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
          />
          <button
            type="button"
            {...cLo(btnCls, "absolute top-0 right-0 mt-2 mr-3", !hasText && "opacity-30")}
            onClick={_sendText}
          >
            <SvgIconFull Path="pen_write" />
          </button>
        </form>
        <button type="button" className={btnCls} onClick={onClickEmoticon}>
          <SvgIconFull Path="smile_emoticon" />
        </button>
        <button type="button" className={btnCls} onClick={onFileClick}>
          <SvgIconFull Path="frame_pic" vLen={20} />
        </button>
      </div>
    </div>
  );
});

export default ChatInputControl;
