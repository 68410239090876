/*=============================================================================
 AddContacts.tsx - Add contacts

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useRef, } from 'react';
import { useAppDispatch } from 'app/store';
import { cL, cLo, errorFmt, } from 'utils/util';
import { SvgIcon, } from 'utils/svg';
import { RegEx_Auth, RegEx_URL, } from 'utils/RegEx';
import { twBtnFocus, AriaCloseButton, } from 'ui/ui';
import { ErrorDlg } from 'ui/forms';
import { SearchBoxForm, } from 'ui/SearchBox';
import { queryUserAPI, } from 'api/usersAPI';
import { useCurrentUser, updateUserContacts, useAllUsers, } from 'features/users/usersSlice';
import { UserInfoByUser } from 'features/users/UserInfo';
import type { IUserEx, } from 'models';
// for ModalDialog
// react-aria ------------------------------------------------------------------------------------
import { OverlayContainer } from '@react-aria/overlays';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useButton }  from '@react-aria/button';
// react-aria ------------------------------------------------------------------------------------
import { ModalDialog } from 'ui/ModalDialog';

/**
 * SeachBox by Email
 * - checks email validity
 */
const SearchBoxEmail = ({ setSearchEmail, setError }: {
  setSearchEmail: (text: string)   => void;  // callback to set email search string
  setError:       (error: TError0) => void;  // callback to set error
}) => {
  const handleInput = (email: string) => {
    if (RegEx_Auth.email.test(email)) {
      // setError(errorFmt("OK", "email", email));
      setSearchEmail?.(email);  // callback only when got valid email
    } else {
      setError(email.match(RegEx_URL.nonempty)
        ? errorFmt("ERROR", "Invalid email", email)
        : errorFmt("WARN", "No email", "Enter an email")
      );
    }
  }
  return (
    <SearchBoxForm placeholder="Search User by Email" setSearchText={handleInput} />
  );
}

/**
 * Add Contact Modal
 * - search user by email
 * - list all users in redux that are not included in contact list yet
 */
export function AddContactModal({ clsButton }: { clsButton?: TClassName; }) {
  const [error, setError] = useState<IError | null>(null);
  const [user,  setUser]  = useState< Nullable<IUserEx> >(null);  // found user
  const [cntSearch, setCntSearch] = useState(0);  // u may need to restrict search counts
  const cUser = useCurrentUser();
  const allUsers = useAllUsers();
  const twUserInfo  = "min-w-[16rem]";
  const twRecommend = "dark:bg-gray-300 dark:text-gray-800 bg-gray-700 text-gray-200";
  const state = useOverlayTriggerState({});
  const openBtnRef  = useRef<HTMLButtonElement>(null);
  const closeBtnRef = useRef<HTMLButtonElement>(null);

  // useButton ensures that focus management is handled correctly, across all browsers.
  // Focus is restored to the button once the dialog closes.
  const { buttonProps: openBtnProps  } = useButton( { onPress: () => state.open()  }, openBtnRef);
  const { buttonProps: closeBtnProps } = useButton( { onPress: () => state.close() }, closeBtnRef);

  const handleSearchText = async (email: string) => {
    let foundUser: Nullable<IUserEx> = null;
    try {
      setCntSearch(cntSearch + 1);
      foundUser = await queryUserAPI(email);
    } catch (error) {
      setError(errorFmt("WARN", "Not Found", "No user found!"));
    }
    setUser(foundUser);
  }
  if (!cUser)
    return null;
  return (
    <>
      <button
        ref={openBtnRef}
        {...openBtnProps}
        {...cLo(twBtnFocus, clsButton)}
      >
        <SvgIcon Path="plus_add" strokeWidth={1} />
      </button>
      {state.isOpen &&
      <OverlayContainer>
        <ModalDialog
          title="Add a New Contact"
          clsBgOpacity="bg-opacity-20"
          clsDialog={cL("relative font-mono bg-gray-200 text-black rounded-lg",
            "min-w-[20rem] h-auto border-2 border-gray-700 dark:border-gray-400 shadow-lg")}
          isOpen
          onClose={state.close}
          isDismissable
        >
          <AriaCloseButton {...{closeBtnRef, closeBtnProps}} />
          <div {...cLo("flex flex-col justify-center rounded-b-md px-1",
              "dark:bg-gray-700 dark:text-gray-300 pb-2")}
          >
            <SearchBoxEmail setSearchEmail={handleSearchText} {...{setError}} />
            {user &&
              <div className="flex flex-row">
                <UserInfoByUser {...cLo(twUserInfo)} {...{user}} bdrClr="border-gray-400" />
                <AddContactButton {...{cUser}} uid={user.uid} />
              </div>}
            <div {...cLo("text-xs text-center rounded-sm mt-2", twRecommend)}>
              Recommended Users
            </div>
            {allUsers
              .filter(u => (u.uid !== cUser.uid) && !cUser.contacts?.includes(u.uid))
              .map(u =>
                <div key={u.uid} className="flex flex-row mb-1">
                  <UserInfoByUser {...cLo(twUserInfo)} user={u} />
                  <AddContactButton {...{cUser}} uid={u.uid} />
                </div>)
            }
            {error && <ErrorDlg {...{error}} />}
         </div>
        </ModalDialog>
      </OverlayContainer>}
    </>
  );
}

const AddContactButton = ({ cUser, uid }: { cUser: IUserEx; uid: TUserID; }) => {
  const dispatch = useAppDispatch();
  const existingContact: Undefinable<boolean> = (cUser.uid === uid) ||
                                                 cUser.contacts?.includes(uid);
  const handleClick = () => {
    dispatch( updateUserContacts({ uid: cUser.uid, id: uid, add: true }));
  }
  return (
    <button
      className="p-2 cursor-pointer rounded-full hover:bg-gray-400 disabled:opacity-10"
      type="button"
      disabled={existingContact}
      {...(!existingContact && {onClick: handleClick})}
    >
      <SvgIcon Path="user_add" />
    </button>
  );
}
