/*=============================================================================
 subAPI.ts - subscribe API

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useEffect } from 'react';
import { useAppDispatch, } from 'app/store'
import { useSelector } from 'react-redux';
import type { IUserCreate, IRoomCreate, IMsg, IMsgCreate, } from 'models';
import { useCurrentUser, userUpsertOne, } from 'features/users/usersSlice';
import { useRoomById, roomUpsertOne } from 'features/rooms/roomsSlice';
import { selectRoomId, msgSetAll, msgUpsertOne, msgRemoveOne, } from 'features/msgs/msgsSlice';
import { chatCfg } from 'features/chat/Chat';
import { fsPath, serializeTime, } from './apiCommon';
import firebase from './firebase';

//-----------------------------------------------------------------------------
// User
//-----------------------------------------------------------------------------
/**
 * subscribe to the user doc to update channel data in realtime
 */
export const useSubscribeCurrentUser = () => {  // Realtime updates
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const uid = user?.uid;

  useEffect(() => {
    if (!uid)
      return;
    //-------------------------------------------------------------------------
    // users/{self-uid}, read
    //-------------------------------------------------------------------------
    const query = firebase.firestore()
      .doc(fsPath.user(uid));
    //-------------------------------------------------------------------------
    const unsubscribe = query.onSnapshot(doc => {  // firebase.firestore.QuerySnapshot
      const data = doc.data();
      console.log("********", fsPath.user(uid), data);
      if (data && !doc.metadata.hasPendingWrites) {  // ignore "Local", only from "Server"
        dispatch( userUpsertOne({
          uid: doc.id,
          ...(data as IUserCreate),
          ...(data.createdAt && { createdAt: serializeTime(data.createdAt) }),
          ...(data.signInAt  && { signInAt:  serializeTime(data.signInAt)  }),
        }) );
      }
    });
    console.log("🍏 subscribed to the Firebase doc", fsPath.user(uid));
    return () => {
      unsubscribe();
      console.log("🍎 unsubscribed Firebase doc", fsPath.user(uid));
    }
  }, [uid, dispatch]);

  return user;
}

//-----------------------------------------------------------------------------
// Room Msgs
//-----------------------------------------------------------------------------
/**
 * extract msg
 */
const extractMsg = (doc: firebase.firestore.QueryDocumentSnapshot): IMsg => {
  const data = doc.data() as IMsgCreate;
  return ({
    ...data,
    id:   doc.id,
    time: serializeTime(doc.data().time),  // serializable
  });
}

/**
 * 1. Subscribe to the room Document:   path "rooms/{roomId}"
 * 2. Subscribe to the msgs Collection: path "rooms/{roomId}/msgs"
 * Local writes in your app will invoke snapshot listeners immediately. ("latency compensation")
 * -> filter out duplicate addition: the local fire comes with no serverTimestamp.
 */
export const useSubscribeMsgsRoom = () => {
  const dispatch = useAppDispatch();
  const user   = useCurrentUser();
  const roomId = useSelector( selectRoomId );
  const room   = useRoomById(roomId);

  useEffect(() => {
    if (!roomId)
      return;
    // if ( !user?.rooms?.includes(roomId) ) {  // prevent entering exited room
    //   console.log(`User didn't join the channel:${roomId}`);
    //   dispatch( msgExitRoom() );  // if a new user didn't join the room, force to exit
    //   return;
    // }
    //-------------------------------------------------------------------------
    // users/{self-uid}, read
    //-------------------------------------------------------------------------
    const query = firebase.firestore()
      .doc(fsPath.room(roomId));
    //-------------------------------------------------------------------------
    const unsubscribe = query.onSnapshot(doc => {  // firebase.firestore.QuerySnapshot
      const data = doc.data();
      console.log("********", fsPath.room(roomId), data);
      if (data && !doc.metadata.hasPendingWrites) { // ignore "Local", only from "Server"
        dispatch( roomUpsertOne({
          id: doc.id,
          ...(data as IRoomCreate),
          ...(data.createdAt && { createdAt: serializeTime(data.createdAt) }),
        }) );
      }
    });
    console.log("🍏 subscribed to the Firebase doc", fsPath.room(roomId));
    return () => {
      unsubscribe();
      console.log("🍎 unsubscribed Firebase doc", fsPath.room(roomId));
    }
  }, [user, roomId, dispatch]);

  useEffect(() => {
    if (!roomId)
      return;
    //-------------------------------------------------------------------------
    // msgs collection, read
    //-------------------------------------------------------------------------
    const query = firebase.firestore()
      .collection(fsPath.msgs(roomId))
      .orderBy("time", "desc")
      .limit(chatCfg.msgsLoadLimit);    // ************* Check later
    //-------------------------------------------------------------------------
    const amsg: IMsg[] = [];
    let bInitial = true;  // first read on subscription
    const unsubscribe = query.onSnapshot(snapshot => {  // firebase.firestore.QuerySnapshot
      if (bInitial) {
        snapshot.forEach(doc => {
          if (!doc.metadata.hasPendingWrites)  // ignore "Local", only from "Server"
            amsg.push( extractMsg(doc) );
        });
        bInitial = false;
        // if (amsg.length > 0) // remove this to enable blank update! May,6,2021
        dispatch( msgSetAll(amsg) );  // fetch all msgs
      } else {
        snapshot.docChanges().forEach(change => {
          switch (change.type) {
            case "added":
            case "modified":
              if (!change.doc.metadata.hasPendingWrites)
                dispatch( msgUpsertOne( extractMsg(change.doc) ) );
              break;
            case "removed":
              dispatch( msgRemoveOne(change.doc.id));
              break;
            default: console.error(change.type, " not covered!");
          }
          console.log("room msg:", change.type, change.doc, change.doc.id);
        });
      }
    }, error => {
      console.error("useSubscribeMsgsRoom:", error);
    });
    console.log("🍏 subscribed to the Firebase collection", fsPath.msgs(roomId));
    return () => {
      unsubscribe();
      console.log("🍎 unsubscribed Firebase collection", fsPath.msgs(roomId));
    }
  }, [user, roomId, dispatch]);

  return room;
}
