/*=============================================================================
 TermsOfUse.tsx - Terms of Use, Privacy Policy, etc.

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useRef } from 'react';
import { cLo } from 'utils/util';
// for ModalDialog
// react-aria ------------------------------------------------------------------------------------
import { OverlayContainer } from '@react-aria/overlays';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useLink, } from '@react-aria/link';
import type { AriaLinkOptions } from '@react-aria/link';
import { useButton }  from '@react-aria/button';
// react-aria ------------------------------------------------------------------------------------
import { ModalDialog } from 'ui/ModalDialog';

export const TermsOfUse = ({ accept }: { accept: string; }) => {
  const terms   = { href: "https://twitter.com/en/tos",     title: "Terms of Use" };
  const privacy = { href: "https://twitter.com/en/privacy", title: "Privacy Policy" }
  return (
    <span {...cLo("ml-2 align-middle font-thin text-xs dark:text-gray-100 text-black")}>
      I {accept} the{' '}
      <ALinkModal href={terms.href}   title={terms.title}>{terms.title}</ALinkModal> &amp;{' '}
      <ALinkModal href={privacy.href} title={privacy.title}>{privacy.title}</ALinkModal>.
    </span>
  );
}

type Thref = Pick< React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' >;
interface IALinkModalProps extends AriaLinkOptions, Thref {
  title: string;
};
const ALinkModal: React.FC<IALinkModalProps> = (props) => {
  const state = useOverlayTriggerState({});
  const openLinkRef = useRef<HTMLAnchorElement>(null);
  const closeBtnRef = useRef<HTMLButtonElement>(null);

  const { linkProps:   openLinkProps } = useLink({ ...props, onPress: () => state.open() }, openLinkRef);
  const { buttonProps: closeBtnProps } = useButton( { onPress: () => state.close() }, closeBtnRef);

  return (
    <>
      <a
        ref={openLinkRef}
        {...openLinkProps}
        className="font-bold text-blue-400 hover:text-blue-300 cursor-pointer"
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
      {state.isOpen && (
        <OverlayContainer>
          <ModalDialog
            title={props.title}
            isOpen
            onClose={state.close}
            isDismissable
          >
            <div className="text-gray-700">
              読め
            </div>
            <div className="flex justify-center">
              <button
                ref={closeBtnRef}
                {...closeBtnProps}
                {...cLo("flex justify-center mt-4 px-2 bg-red-700 text-white",
                  "rounded-md shadow-lg hover:bg-red-800")}
              >
                OK
              </button>
            </div>
          </ModalDialog>
        </OverlayContainer>
      )}
    </>
  );
}

