/*=============================================================================
 Routes.tsx - Route Definitions for Menu Labels and Components

 - used by <NavLink> and <Route>
 - Not all route paths are mapped to the menu,
   but all route paths should have a handling component

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import type { IAuthRouteProps } from './AuthRoute';
// ----------------------------------------------------------------------------
// Components for each routed page
// ----------------------------------------------------------------------------
import AuthPage  from 'features/auth/AuthPage';
import RootPage  from 'features/root/RootPage';
import Dashboard from 'features/dash/Dashboard';
import Calendar  from 'features/cal/Calendar';
import Timeline  from 'features/timeline/Timeline';
import DnDKanban from 'features/kanban/DnDKanban';
import Shop      from 'features/shop/Shop';
import Chat      from 'features/chat/Chat';
import Profile   from 'features/profile/ProfilePage';
import Setting   from 'features/setting/SettingPage';
// ----------------------------------------------------------------------------
import { SvgIcon, } from 'utils/svg';

// ----------------------------------------------------------------------------
/**
 * Routes
 * Route paths, constants
 */
const ROUTES = {
  root:     "/",
  dash:     "/dash",
  chat:     "/chat",
  calendar: "/calendar",
  timeline: "/timeline",
  kanban:   "/kanban",
  auth:     "/auth",
  profile:  "/profile",
  setting:  "/setting",
  shop:     "/shop",
};

// ----------------------------------------------------------------------------
/**
 * Route components: The ORDER matters!
 * "/" SHOULD come last, otherwise root page will match first.
 *
 * - connects the route with a component and sets each access level
 * Connects Components to the Route path
 * Route - Component map for react-router's <Route>
 * react-router: component and render cannot be used at the same time
 * SubRoutes not supported yet.
 * - access level: 0 public, 1: login required
 *
 */
export const RouteCompos: Partial<IAuthRouteProps>[] =  [
  { path: ROUTES.chat,     children: <Chat />,      access: 1 },
  { path: ROUTES.calendar, children: <Calendar />,  access: 0 },
  { path: ROUTES.timeline, children: <Timeline />,  access: 0 },
  { path: ROUTES.kanban,   children: <DnDKanban />, access: 0 },
  { path: ROUTES.shop,     children: <Shop />,      access: 0 },
  { path: ROUTES.auth,     children: <AuthPage />,  access: 0 },
  { path: ROUTES.profile,  children: <Profile />,   access: 1 },
  { path: ROUTES.setting,  children: <Setting />,   access: 1 },
  { path: ROUTES.dash,     children: <Dashboard />, access: 0 },
  { path: ROUTES.root,     children: <RootPage />,  access: 0, exact: true },
];

// ----------------------------------------------------------------------------
/**
 * Connects Menu Labels to the Route path
 * Route - Menu map for react-router's <Link> or <NavLink>
 */
interface IRouteMenuProps {
  to:     ValueOf< typeof ROUTES >;
  title?: stringU;
  icon?:  React.ReactNode;
}
/**
 * Menus list for Desktop
 * title will be shown if icon is not provided
 */
export const RouteMenus: IRouteMenuProps[] = [
  { to: ROUTES.root,     icon: <SvgIcon Path="home_nav" /> },
  { to: ROUTES.dash,     title: "DB" },
  { to: ROUTES.chat,     title: "Chat" },
  { to: ROUTES.calendar, title: "Calendar" },
  { to: ROUTES.timeline, title: "Timeline" },
  { to: ROUTES.kanban,   title: "Kanban" },
  { to: ROUTES.shop,     title: "Shop" },
];

const STYLE = { strokeWidth: 0 };
/**
 * Menus list for Mobile
 * icon will be shown, titles are for tooltips
 */
export const RouteMobileMenus = [
  { to: ROUTES.root,     title: "Home",      icon: <SvgIcon            Path="home_nav" /> },
  { to: ROUTES.dash,     title: "Dashboard", icon: <SvgIcon {...STYLE} Path="pen_write" /> },
  { to: ROUTES.chat,     title: "Chat",      icon: <SvgIcon {...STYLE} Path="bubble_chat" /> },
  { to: ROUTES.calendar, title: "Calendar",  icon: <SvgIcon {...STYLE} Path="note_calendar2" /> },
  { to: ROUTES.timeline, title: "Timeline",  icon: <SvgIcon            Path="note_calendar" /> },
  { to: ROUTES.kanban,   title: "Kanban",    icon: <SvgIcon {...STYLE} Path="note_blog" /> },
  { to: ROUTES.shop,     title: "Shop",      icon: <SvgIcon {...STYLE} Path="cart_shop" /> },
];
