/*=============================================================================
 Shop.tsx - Shop page

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { cLo, } from 'utils/util';

export default function ShopPage() {
  return (
    <>
      <h1 className="mt-8 text-center text-3xl font-thin dark:text-white text-black leading-loose">
        <span className="font-bold">SpacetimeQ Inc 開発アイテム</span><br/>
      </h1>
      <ol {...cLo("ml-8 mt-8 text-base font-mono dark:text-white text-black leading-10")}>
        <li>クローズド・コミュニティ構築サービス</li>
        <li>メッセージング基盤のECサービス</li>
        <li>個人ノート作成・チャット・コラボレーション・ツール</li>
      </ol>
    </>
  );
}
