/*=============================================================================
 SearchBox.tsx - search

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState } from 'react';
import { SvgIconFull, } from 'utils/svg';
import { cLo, } from 'utils/util';
import { CloseButton, } from 'ui/ui';

export interface ISearchBoxProps extends IClassNameObj {
  placeholder?:   string;
  setSearchText?: (text: string) => void;  // callback
  onClearCB?:     () => void;  // callback for CloseButton pressed
};
/**
 * SearhBox Form - renders on ENTER (requires ENTER for refresh)
 */
export const SearchBoxForm = ({
  className,
  placeholder,
  setSearchText,
}: ISearchBoxProps) => {
  const [text, setText] = useState('');
  const onFormSubmit: React.FormEventHandler = (ev) => {
    ev.preventDefault();  // to prevent page reload on form submit
    // setText('');
    setSearchText?.(text);
  }
  return (
    <form onSubmit={ev => onFormSubmit(ev)}>
      <SearchBox
        {...{className, placeholder}}
        setSearchText={setText}
        onClearCB={() => setSearchText?.('')}
      />
    </form>
  );
}

/**
 * SearhBox - renders on every key input
 */
export const SearchBox = ({
  className = "w-full py-0.5 pl-8 pr-4",
  placeholder = "Search",
  setSearchText,
  onClearCB
}: ISearchBoxProps) => {
  const [text, setText] = useState('');
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    ev.preventDefault();  // to prevent page reload on form submit
    setText(ev.target.value);
    setSearchText?.(ev.target.value);
  }
  const clearSearch = () => {
    setText('');
    setSearchText?.('');
    onClearCB?.();
  }
  return (
    <div {...cLo("flex-none p-1")}>
      <label className="relative">
        <input
          {...cLo(className, "shadow-inner shadow-md border rounded-lg",
            "dark:text-white dark:bg-gray-800 dark:border-gray-600",
            "dark:focus:border-gray-200 dark:focus:bg-gray-900",
            "text-black bg-gray-300 border-gray-400 focus:border-gray-800 focus:bg-gray-200",
            "placeholder-gray-400",
            "focus:outline-none focus:shadow-md transition duration-100 ease-in")}
          type="text"
          value={text}
          onChange={handleChange}
          {...{placeholder}}
        />
        <span {...cLo("absolute top-0 left-0 inline-block ml-1 -mt-0.5 h-6 w-6 opacity-50",
          "dark:text-gray-200")}
        >
          <SvgIconFull Path="lens_search" />
        </span>
        <CloseButton
          {...cLo("absolute top-0 right-0 mr-1 h-4 w-4 border")}
          onPress={clearSearch}
        />
      </label>
    </div>
  );
}
