/*=============================================================================
 chat/Chat.tsx - Chat Main Window

  ChatMainWindow
  ├── Avatars         - common gadgets
  ├── PaneRooms       - search/sessions (top slide pane)
  │   ├── SearchRoom    - 
  │   └── Rooms         - rooms list (toggle between joined/all)
  ├── PaneUsers       - search/session members/contacts (left pane)
  │   ├── SearchUser    -
  │   ├── RoomUsers     - users list in the current room
  │   └── Contacts      - contacts list
  ├── PaneDetails     - pane for additional contents view (optional)
  │   ├── 
  │   └── 
  └── PaneChat        - Chat pane: parse chat inputs - onNewMsg
     ├── Commands       - commands interpreter
     ├── ChatInput      - Input control
     │   ├── Workbook     - input draft edit area
     │   ├── Preview      - preview inputs before sending (toggle view)
     │   └── Emojis       - emojis (popup)
     ├── ChatParse    - Parsed Content viewers
     │   ├── Bubble       - text in bubble
     │   ├── MathView     - Math Equations KaTeX
     │   ├── MDXView      - Markdown viewer
     │   └── EmbedView    - Embed (URL, documents) viewer
     ├── ChatTime     - time format
     └── Reactions    - reaction buttons

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useRef, useState, useEffect } from 'react';
import { cLo, cLoIf, } from 'utils/util';
import { useSubscribeMsgsRoom, useSubscribeCurrentUser, } from 'api/subAPI';
import { fetchUsers, fetchRooms, } from 'api/fetchData';
import { useAppDispatch } from 'app/store';
import { useWindowSize } from 'utils/windows';
import PaneRooms   from './PaneRooms';
import PaneChat    from './PaneChat';
import LeftSideDrawer from './LeftSideDrawer';

export const chatCfg = {  // config and constants for chat
  msgsLoadLimit: 1200,  // messages; msgsAPI.ts, to query data from DB
  maxTextLen:    400,   // characters; ChatBubble, to trim a long text in a bubble
};

// transition-spacing works, but it's not in tailwind doc.
/**
 * The whole chat frame that includes PaneRooms, PaneChat, and PaneDetails
 * IMPORTANT! At <section>, set max-width responsive to the window.innerWidth
 */
export default function ChatMainWindow() {
  const refPush = useRef<HTMLDivElement>(null);  // allow <Sidebar> to handle <div> with refPush
  const [showRooms, setShowRooms] = useState(true);  // Show rooms list
  const { w: maxWidth, } = useWindowSize();
  const vertical = maxWidth <= 360;  //> Sidebar vertical move for mobile
  const dispatch = useAppDispatch();
  const twBorder = "dark:border-gray-800 border-gray-400";
  //---------------------------------------------------------------------------
  // Subscribe to user/room doc and room.msgs collection
  //---------------------------------------------------------------------------
  const user = useSubscribeCurrentUser();
  const room = useSubscribeMsgsRoom();
  //---------------------------------------------------------------------------
  useEffect(() => {  // TODO: prevent repetitive API requests!
    console.log("Chat:useEffect()");
    fetchUsers(dispatch, user?.contacts);
    fetchRooms(dispatch, user?.rooms);  // with room users
    // fetchRooms(dispatch, user?.xRooms);  // TODO: Do we need to fetch exited rooms?
  }, [user, room, dispatch]);
  const disabled = !user;
  // console.log("Chat:ChatMainWindow()", user?.uid);
  if (!room && !showRooms)
    setShowRooms(true);  // UI bug report by James, 2021-07-15
  return (
    <>
      <LeftSideDrawer {...{vertical, refPush, disabled}} />
      <main ref={refPush}
        {...cLo("flex w-full overflow-hidden antialiased Sq_h-max Drawer_transition_padding",
        "dark:text-gray-200 dark:bg-gray-900 text-gray-700 bg-gray-200")}
      >
        <section {...cLo("flex flex-col flex-auto border-l", twBorder)}
          style={{ maxWidth }}
        >
          <PaneRooms {...cLoIf(showRooms)} />
          {room
          ? <PaneChat {...{room}} showRoomsCB={() => setShowRooms(!showRooms)} />
          : <p {...cLo("p-2 w-full font-mono font-bold text-center text-white bg-gray-700")}>
              Select a channel
            </p>}
        </section>
      </main>
    </>
  );
}
