/*=============================================================================
 Dashboard/index.tsx - description

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useState } from 'react';
import { SvgIcon, } from 'utils/svg';
import LikeButton from 'ui/LikeButton';
import POST_DATA from 'asset/test/postDataTest.json';

POST_DATA.reverse();

interface IPostItemProps {
  avatar:   string;
  author:   string;
  url:      string;
  time:     string;
  img?:     string;
  likes:    number;
  comments: number;
};
const PostItem: React.FC<IPostItemProps> = ({
  avatar, author, url, time, img, likes, comments,
  children
}) => {
  const [cntLike, setCntLike] = useState(likes);
  const [cntCmt,  setCntCmt]  = useState(comments);

  const handleLike: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCntLike(cntLike + (e.target.checked ? 1 : -1));
  }

  return (
    <div className="max-w-xl mx-auto mt-2 px-2 py-4 dark:bg-white bg-blue-100 shadow-md rounded-lg">
      <div className="py-2 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <a href={url} className="flex flex-row items-center focus:outline-none focus:shadow-outline rounded-lg">
            <img className="rounded-full h-8 w-8 object-cover" src={avatar} alt="avatar" />
            <p className="ml-2 text-base font-medium">{author}</p>
          </a>
        </div>
        <div className="flex flex-row items-center">
          <p className="text-xs font-semibold text-gray-500">{time}</p>
        </div>
      </div>
      <div className="mt-2">
        {img && <img className="object-cover w-full rounded-lg" src={img} alt="background" />}
        <div className="py-2 flex flex-row items-center font-mono">
          <div
            className="flex flex-row items-center focus:outline-none focus:shadow-outline rounded-lg"
          >
            <LikeButton onChange={handleLike} />
            <span className="ml-1">{cntLike}</span>
          </div>
          <button
            className="flex flex-row items-center focus:outline-none focus:shadow-outline rounded-lg ml-3"
            onClick={() => setCntCmt(cntCmt + 1)}
          >
            <SvgIcon
              className="text-green-800"
              fill="rgba(0,128,0,.2)"
              Path="bubble_comment"
            />
            <span className="ml-1">{cntCmt}</span>
          </button>
        </div>
      </div>
      <div className="py-2">
        <p className="leading-snug">{children}</p>
      </div>
    </div>
  );
}

export default function Dashboard() {
  return (
     <div className="flex-1 px-4 md:px-6 lg:px-8 overflow-y-auto">
       {POST_DATA.map(pd => {
         const { id, content, ...rest } = pd;
         return <PostItem key={id} {...rest}>{content}</PostItem>;
       })}
    </div>
  );
}
