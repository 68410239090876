/*=============================================================================
 storageAPI.ts - storage upload API

 - Rules:
   match /b/{bucket}/o {
    match /users/{userId}/{fileName} {
      allow write: if request.auth != null && request.auth.uid == userId && isImageBelowMaxSize(10);
      allow read;
    }
 - https://www.sentinelstand.com/article/guide-to-firebase-storage-download-urls-tokens
 - download URL is a long-lived, publicly accessible URL with a random UUIDv4
   https://firebasestorage.googleapis.com/v0/b/<projectId>.appspot.com/o/image.jpg?alt=media&token=<token>
   https://firebasestorage.googleapis.com/v0/b/stqchat.appspot.com/o/stqm%2FParis-paint.jpeg?alt=media&token=e24f0ef9-e2bd-4d8c-9a28-a2ab9747606f

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import firebase from './firebase';
import { fsPath, } from './apiCommon';
import { CREQ, WARN_REQ_IN_QUEUE } from './reqQueue';

/** upload File to storage
 *  @returns downloadURL promise
 */
export const uploadFile = async (uid: TUserID, file: File) =>
  new Promise<string>(async (resolve, reject) => {
    if (!CREQ.addReq(file.name, "uploadFile")) {
      reject(WARN_REQ_IN_QUEUE);
      return;
    }
    try {
      //-----------------------------------------------------------------------
      // storage upload with File
      //-----------------------------------------------------------------------
      const upTask = firebase.storage()
        .ref()
        .child(`${fsPath.users}/${uid}/${file.name}`)
        .put(file);
      //-----------------------------------------------------------------------
      upTask.on('state_changed',
        snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log('Upload is running');
              break;
            default:
          }
        },
        error => {
          console.error(error);
        },
        () => { // handle successful uploads on complete
          upTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("Successfully uploaded:", downloadURL);
            resolve(downloadURL);
          });
        }
      );
    } catch (error) {
      reject(error);
    } finally {
      CREQ.removeReq(file.name);
    }
  });
