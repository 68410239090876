/*=============================================================================
 EditRoom.tsx - Edit (Create or Edit) room (channel)


 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useRef, useState, } from 'react';
import { useAppDispatch } from 'app/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { cL, cLo, cCo, errorFmt, } from 'utils/util';
import { ErrorDlg } from 'ui/forms';
import { twBtnFocus, AriaCloseButton, } from 'ui/ui';
import { SvgIcon, } from 'utils/svg';
import type { IRoom, } from 'models';
import { updateUserRooms, useCurrentUser, } from 'features/users/usersSlice';
import { msgEnterRoom, } from 'features/msgs/msgsSlice';
import { UserInfo, UsersList, } from 'features/users/UserInfo';
import { createRoom, } from 'features/rooms/roomsSlice';
import { AvatarByUid, OnLight, } from 'ui/Avatars';
import type { IFilePreview, } from 'utils/DnDOpenFile';
import { DnDOpenFile, strDnDInfo, } from 'utils/DnDOpenFile';
import { RegEx_URL, } from 'utils/RegEx';
import { uploadFile, } from 'api/storageAPI';
import { TagInput, } from 'ui/Tags';
// for ModalDialog
// react-aria ------------------------------------------------------------------------------------
import { OverlayContainer } from '@react-aria/overlays';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useButton }  from '@react-aria/button';
// react-aria ------------------------------------------------------------------------------------
import { ModalDialog } from 'ui/ModalDialog';

import IMAGES from 'asset/imgurls.json';

interface IRoomCreateProps extends IClassNameObj {
  uids:   TUserID[];  // other users' uid list
  label?: string;     // label on the anchor button
};
/**
 * Edit Room
 * @param className tailwind classes for the launch button
 * @param uids a list of uids to participate in the channel
 * @param label for the launch button
 * issues: on touch event, need to use stopPropagation
 */
export function EditRoomModal({ className = twBtnFocus, uids, label }: IRoomCreateProps) {
  const state = useOverlayTriggerState({});
  const openBtnRef  = useRef<HTMLButtonElement>(null);
  const closeBtnRef = useRef<HTMLButtonElement>(null);

  // useButton ensures that focus management is handled correctly, across all browsers.
  // Focus is restored to the button once the dialog closes.
  const { buttonProps: openBtnProps  } = useButton( { onPress: () => state.open() }, openBtnRef);
  const { buttonProps: closeBtnProps } = useButton(
    { onPress: () => state.close() },  // Can we use stopPropagation?
    closeBtnRef
  );
  return (
    <>
      <button
        ref={openBtnRef}
        {...openBtnProps}
        {...cLo(className, label && "flex flex-row items-center")}
      >
        <SvgIcon Path="door_enter" strokeWidth={1} />
        {label && <span {...cLo("ml-1")}>{label}</span>}
      </button>
      {state.isOpen &&
      <OverlayContainer>
        <ModalDialog
          title="Create a New Channel"
          clsBgOpacity="bg-opacity-20"
          clsDialog={cL("relative mx-8 lg:w-2/3 font-mono bg-gray-200 dark:bg-gray-700 text-black",
            "rounded-lg border-2 border-gray-700 dark:border-gray-400 shadow-lg")}
          isOpen
          onClose={state.close}
          isDismissable
        >
          <AriaCloseButton {...{closeBtnRef, closeBtnProps}} />
          <EditRoomForm {...{uids}}
            handleSubmit={() => state.close()}
          />
        </ModalDialog>
      </OverlayContainer>}
    </>
  );
}

interface IEditRoomFormProps extends IRoomCreateProps {
  handleSubmit: () => void;  // callback
};
interface IBackImg {
  url?: string;
  dnd?: IFilePreview;
};
/**
 * Edit Room form
 * - Allowing title and backURL as undefined causes the input to be uncontrolled.
 */
const EditRoomForm = ({ className, uids, handleSubmit }: IEditRoomFormProps) =>
{
  const cUser = useCurrentUser();
  const [error,   setError]   = useState<TError0>(null);
  const [uidList, setUidList] = useState<TUserID[]>(uids);
  const [title,   setTitle]   = useState<string>('');
  const [backImg, setBackImg] = useState<IBackImg>();     // background from DnD
  const [tags,    setTags]    = useState<string[]>([]);
  const dispatch = useAppDispatch();
  if (!cUser)
    return null;
  const twInput  = "font-mono px-1 w-full dark:bg-gray-800";
  const twBorder = "border border-gray-400 shadow-md rounded-md";
  const isOther = (uid: TUserID) => uid !== cUser.uid;

  const handleAvatarClick = (uid: TUserID) => {
    if (isOther(uid))  // remove from the room members list
      setUidList(uidList.filter(id => id !== uid));
  }
  const handleUidClick = (uid: TUserID) => {
    if (!uidList.includes(uid))   // if not already in the list, add it
      setUidList([...uidList, uid]);
  }

  const onFormSubmit: React.FormEventHandler = async (ev) => {
    ev.preventDefault();  // to prevent page reload on form submit
    console.log("onSubmit", title, backImg?.url || backImg?.dnd?.name);
    if (!title) {
      setError(errorFmt('WARN', "No Title", "Enter a channel title!"));
      return null;
    }
    // if (!backImg) {  // Let background image be optional
    //   setError(errorFmt('WARN', "No Backdrop", "Set a background image!"));
    //   return null;
    // }
    let backURL = backImg?.url;
    if (backImg?.dnd) {
      try {
        backURL = await uploadFile(cUser.uid, backImg.dnd);
        console.log("DownloadURL:", backURL);
      } catch (error) {
        setError(errorFmt('ERROR', "File Upload", error));
        return null;
      }
    }
    if (!backURL) {
      setError(errorFmt('WARN', "No Image", "Select a background image!"));
      return null;
    }
    try {
      //-----------------------------------------------------------------------
      // create room
      //-----------------------------------------------------------------------
      const resultAction = await dispatch( createRoom({
        title,
        backURL,
        tags,
        users:  uidList,
      }) );
      //-----------------------------------------------------------------------
      unwrapResult(resultAction);
      console.log(resultAction);
      const newRoom = resultAction.payload as IRoom;
      //-----------------------------------------------------------------------
      // When a room is created, update all the participants' rooms list
      //-----------------------------------------------------------------------
      uidList.forEach(uid => dispatch( updateUserRooms({ uid, id: newRoom.id, add: true })) );
      dispatch( msgEnterRoom(newRoom.id) );
      //-----------------------------------------------------------------------
      handleSubmit();
    } catch (error) {
      console.log("dispatch ( createRoom ):", error);
      setError(errorFmt('ERROR', "Room Creation", "Failed to create a room!"));
    }
  }

  return (
    <div {...cLo(className, "flex flex-col justify-center rounded-b-md",
        "dark:bg-gray-700 dark:text-gray-300")}
    >
      <DnDOpenFile
        url={backImg?.url}
        urlFirst={!!backImg?.url}
        {...cLo("relative flex-1 flex flex-col justify-center items-center w-full")}
        clsBorder="max-h-[400px] overflow-y-scroll"
        onFileCB={f => setBackImg({ dnd: f })}
      />
      <div {...cLo("mx-2 text-2xs dark:text-blue-300 text-blue-700")}>
        {backImg?.url || strDnDInfo(backImg?.dnd, true)}
      </div>
      <div {...cLo("flex flex-row")} >
        <div {...cLo("flex items-center mx-2 FontDigital text-6xl")}>{uidList.length}</div>
        <div {...cLo("flex flex-row max-w-full overflow-x-scroll mt-1 p-1")} >
          {uidList?.map(uid =>
            <div key={uid}
              {...cLo("flex flex-col cursor-pointer")}
              onClick={() => handleAvatarClick(uid)}
            >
              <AvatarByUid
                {...cLo("w-10 h-10 md:w-16 md:h-16")}
                {...{uid}}
                clsLabel="text-3xs max-w-[2.4rem] md:text-3xs md:max-w-[4rem]"
                bdrClr={cL("border-blue-400", isOther(uid) && "hover:border-red-500")}
              >
                <OnLight
                  onColor={isOther(uid) ? "bg-green-500" : "bg-pink-500"}
                  pulse={!isOther(uid)}
                />
              </AvatarByUid>
            </div>
          )}
        </div>
      </div>
      <form {...cLo("mx-2")} onSubmit={ev => onFormSubmit(ev)}>
        <fieldset className="flex flex-col mb-2">
          <LabelFrame {...cLo("mt-2")} label="Title">
            <input
              type="text"
              {...cLo(twInput, "py-0.5 border-none rounded-md",
                "placeholder-gray-300 dark:placeholder-gray-700")}
              placeholder="Channel Title"
              value={title}
              onChange={ev => setTitle(ev.target.value)}
            />
          </LabelFrame>
          <LabelFrame {...cLo("mt-2")} label="URL">
            <EditURL {...cLo(twInput, "py-0")}
              backURL={backImg?.url}
              onApply={ url => setBackImg({ url }) }
            />
          </LabelFrame>
          <LabelFrame {...cLo("mt-2")} label="Tags">
            <TagInput {...cLo(twInput)}
              {...{tags, setTags}}
              maxTags={8}
            />
          </LabelFrame>
        </fieldset>
        <div {...cLo("flex flex-col sm:flex-row")}>
          <div {...cLo("flex flex-col max-h-28 sm:max-h-60 sm:min-w-[16rem] overflow-y-scroll",
              twBorder, "mr-1")}
          >
            <UsersList emptyMsg="No contacts">
              {cUser.contacts
                ?.filter(uid => !uidList?.includes(uid))
                .map(uid =>
                  <UserInfo key={uid}
                    {...{uid}}
                    bdrClr="border-gray-400"
                    clickUserCB={handleUidClick}
                  />)}
            </UsersList>
          </div>
          <div {...cLo("flex flex-row max-h-28 sm:max-h-60 flex-wrap overflow-auto", twBorder)}>
            {IMAGES.backURL.map((src, key) =>
              <img {...{key, src}}
                {...cCo("m-1 w-auto h-10 md:h-20 cursor-pointer rounded-lg border-2",
                  src === backImg?.url,
                  "border-red-400", "border-gray-500 opacity-60 hover:opacity-100")}
                alt="thumbnails"
                onClick={() => setBackImg({ url: src })}
              />)}
          </div>
        </div>
        <div className="flex justify-center my-4">
          <button
            {...cLo("flex flex-row mx-2 p-2 cursor-pointer rounded-full",
              "bg-gray-100 dark:bg-gray-500 border border-gray-700 shadow-md focus:outline-none",
              "hover:bg-gray-300 dark:hover:bg-gray-400 dark:text-gray-200 disabled:opacity-30")}
            type="submit"
          >
            <SvgIcon Path="pen_write" classX="mx-1" strokeWidth={0} />
            Create a Channel
          </button>
        </div>
      </form>
      {error && <ErrorDlg {...{error}} />}
    </div>
  );
}

/**
 * Label Frame
 * - Be sure to be declared out of the render loop
 *
 * bug? React double renders if inside <label> block;
 */
const LabelFrame: React.FC<{ label: string; } & IClassNameObj > = ({
  className, label, children
}) =>
  <div {...cLo("flex flex-row", className)}>
    <span className="font-bold mr-2">{label}</span>
    {children}
  </div>;

interface IEditURLProps extends React.InputHTMLAttributes<HTMLInputElement> {
  backURL: stringU;
  onApply: (text: string) => void;  // callback
};
/**
 * Edit URL
 */
const EditURL: React.FC<IEditURLProps> = ({
  className: cn,  // className for <input>
  backURL,        // current backURL set
  onApply,
  ...props
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [url, setUrl] = useState<string>('');  // edit buffer in this component
  const isDisabled = !url.match(RegEx_URL.nonempty);  // empty string

  // useButton ensures that focus management is handled correctly, across all browsers.
  // Focus is restored to the button once the dialog closes.
  const { buttonProps } = useButton({
    onPress: () => url && onApply(url),
    isDisabled,
  }, ref);
  return (
    <>
      <input
        type="text"
        placeholder="Background Image URL"
        {...cLo(cn, "text-xs border-none", (url !== backURL) && "opacity-40")}
        {...props}
        value={url}
        onChange={ev => setUrl(ev.target.value)}
      />
      <button {...{ref}}
        {...buttonProps}
        {...cLo("rounded-md dark:text-gray-300 dark:bg-gray-800 dark:hover:bg-gray-500",
            "bg-gray-300 hover:bg-gray-400 focus:outline-none cursor-pointer",
            "text-xs px-2 mx-1 disabled:opacity-10 border border-gray-200")}
      >
        Apply
      </button>
    </>
  );
}
