/*=============================================================================
 forms.tsx - common forms UI

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { cL, cLo, } from 'utils/util';
import { useClose, CloseButton, SpinningCircle, IconButton, } from 'ui/ui';
import type { TIconKindColors, } from 'ui/ui';

/**
 * Error Message Dialog
 */
export const ErrorDlg = ({ error }: { error: IError }) =>
  <IconDialog
    kind={error.kind || 'ERROR'}
    title={error.code}
    content={error.message}
  />

/**
 * Icon dialog
 */
export const IconDialog = ({ kind, title, content }: {
  kind:     TMessageKind;
  title:    string0;
  content:  string;
}) => {
  const { hide, handleClose } = useClose('opacity-50');  // default "Ani"
  const colorsBack: TIconKindColors = { // background, border,     dark:
    ERROR: cL("bg-red-200 border-red-300",       "dark:bg-red-800 dark:border-red-600"),
    OK:    cL("bg-green-200 border-green-300",   "dark:bg-green-800 dark:border-green-600"),
    WARN:  cL("bg-yellow-200 border-yellow-300", "dark:bg-yellow-700 dark:border-yellow-500"),
    INFO:  cL("bg-blue-200 border-blue-300",     "dark:bg-blue-800 dark:border-blue-600"),
  };
  const colorsText: TIconKindColors = {
    ERROR: "text-red-800 dark:text-yellow-200",
    OK:    "text-green-800 dark:text-white",
    WARN:  "text-yellow-800 dark:text-gray-300",
    INFO:  "text-blue-800 dark:text-white",
  }
  return (
    <div {...cLo("mx-20 sm:mx-40 my-2 p-2 flex flex-row items-center",
        "rounded-lg border-b-2 shadow-lg",
        "transition-colors duration-500", colorsBack[kind], hide)}
    >
      <IconButton
        {...{kind}}
        onPress={handleClose}
      />
      <div className="ml-4 font-mono">
        <div {...cLo("font-semibold text-base", colorsText[kind])}>
          {title}
        </div>
        <div className="text-sm dark:text-gray-200">
          {content}
        </div>
      </div>
    </div>
  );
}

interface IAvatarDlgProps {
  img:       string;
  title:     string;
  message:   string;
  close?:    boolean;  // enable close button
  boxClass?: string;   // box width
  obiClass?: string;   // obi(color band) width and color
};
/**
 * Message with Avatar and close button
 */
export const AvatarDlg = ({
  img, title, message, close,
  boxClass = "max-w-lg",
  obiClass = "w-2 dark:bg-gray-400 bg-gray-800",
}: IAvatarDlgProps) => {
  const { hide, handleClose } = useClose();  // "Ani"
  return(
    <div {...cLo("flex justify-around m-2", hide)}>
      <div
        {...cLo("flex shadow-lg rounded-lg border overflow-hidden relative", boxClass,
          "transition-colors duration-500",
          "dark:bg-gray-800 dark:border-gray-600",
          "bg-gray-50 border-gray-300")}
      >
        <div {...cLo("flex-shrink-0 mr-1", obiClass)}></div>
        <div className="flex items-center px-2 py-3">
          <img
            className="w-12 h-12 object-cover rounded-full Scale_hover border dark:border-pink-200"
            src={img}
            alt="avatar-message"
          />
          <div className="mx-3">
            <h2 className="text-xl font-semibold dark:text-white text-gray-800">{title}</h2>
            <p className="text-base dark:text-gray-200 text-gray-600">{message}</p>
          </div>
        </div>
        {close &&
          <CloseButton onPress={handleClose} />}
      </div>
    </div>
  );
}

interface IPendingDlgProps extends Partial<IMouseProps> {
  text?:    string;
  colors?:  string;
};
/**
 * Pending/Wait/Loading Message
 */
export const PendingDlg = ({
  text   = "Loading...",
  colors = "text-white bg-rose-500 hover:bg-rose-800",
  onClick
}: IPendingDlgProps) => {
  return (
    <div className="flex justify-around mt-2 max-h-16">
      <span className="inline-flex rounded-md shadow-sm">
        <div
          role="button"
          {...cLo("inline-flex items-center px-4 py-2 border border-transparent text-base",
            "leading-6 font-medium rounded-md", colors,
            "transition ease-in-out duration-150", !onClick && "cursor-not-allowed")}
          onClick={onClick}
        >
          <SpinningCircle />
          {text}
        </div>
      </span>
    </div>
  );
}

