/*=============================================================================
 App.tsx - App main

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { DarkThemeProvider, } from 'ui/darkThemeCtx';
import Layout from 'ui/layout/Layout';
import { consoleLogo } from 'ui/ui';
import 'styles/App.scss';

document.cookie = 'cross-site-cookie=stq; SameSite=None; Secure';

consoleLogo("STQM");

function App() {
  return (
    <DarkThemeProvider>
      <div className="App">
        <Layout />
      </div>
    </DarkThemeProvider>
  );
}

/*
      <AuthPage loginMode={!user} />
      {user && <Profile />}
      {user && user.emailVerified && <Content />}
 * A <Switch> looks through all its children <Route> elements and renders
 * the first one whose path matches the current URL. Use a <Switch> any time
 * you have multiple routes, but you want only one of them to render at a time.
 * */

export default App;

/*
    <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v7.0&appId=301405370454910&autoLogAppEvents=1"></script>
      <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwsj%2Fvideos%2F276560983497022%2F&show_text=0&width=560" width="560" height="315" title="WSJ"></iframe>
      <br/>
  <iframe
    title ="instagram"
    src="https://www.instagram.com/p/B3aztQPFoTQ/embed"
    width="320"
    height="400"
    frameBorder="1"
    scrolling="no"
    allowTransparency={true}>
  </iframe>
  <iframe

    title="youtube"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/vsl3gBVO2k4"
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen>
  </iframe>

 */

