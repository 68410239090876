/*=============================================================================
 Profile.tsx - User profile

 - user info can be gathered from three sources
   1. firebase authenticated user: firebase.User
   2. STQM application-specific user info: IUser - subset of firebase.User
   3. Extended user info: IUserEx - user entered additional info

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useRef, useState, } from 'react';
import { useAuthCtx } from 'app/AuthContext';
import { currentUser } from 'api/authAPI';
import { createUser, useUserById, } from 'features/users/usersSlice';
import { EmailVerified, } from 'features/users/UserInfo';
import { apiReq } from 'features/auth/authSlice';
import { useAppDispatch } from 'app/store';
import { ButtonC, } from 'ui/ui';
import { getPathD, SvgIcon, } from 'utils/svg';
import type { ISvgIconProps, } from 'utils/svg';
import { DnDOpenFile } from 'utils/DnDOpenFile';
import { timeUMTlocal } from 'utils/datetime';
import { FALLBACK_BKG, ANON_AVATAR, } from 'app/res';
import { cL, cLo, cLoIf, } from 'utils/util';
import type { IUserEx } from 'models';
// for ModalDialog
// react-aria ------------------------------------------------------------------------------------
import { OverlayContainer } from '@react-aria/overlays';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useButton }  from '@react-aria/button';
// react-aria ------------------------------------------------------------------------------------
import { ModalDialog } from 'ui/ModalDialog';

interface IEditable {
  editable?: boolean;
}

/**
 * Register if the user not exist yet.
 * deprecated: now it's automatically set when the user first logs in.
 */
const Register = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuthCtx();
  return (
    <div className="font-semibold text-lg text-center">
      <ButtonC
        className="w-60 m-2"
        onPress={() => dispatch( createUser(user!) )}
      >
        Register
      </ButtonC>
    </div>
  );
}

/**
 * send an email verification
 */
const ButtonEmailVerification = () => {
  const dispatch = useAppDispatch();
  return (
    <span className="font-mono text-xs text-red-400 ml-2">
      <button
        type="button"
        className="rounded-lg border border-rose-400 px-2 hover:text-white hover:bg-rose-800"
        onClick={() => dispatch( apiReq({ command: 'sendEmailVerification' }) )}
      >
        Verify Email
      </button>
    </span>
  );
}

const twBgColor = "dark:bg-gray-300 bg-white";

/**
 * Profile page - self
 */
export default function ProfilePage() {
  return (
    <div {...cLo("h-screen overflow-hidden flex flex-col items-center justify-start mt-4")}>
      <div {...cLo("max-w-lg min-w-lg mx-2 lg:w-1/2 rounded-lg shadow-lg overflow-hidden")}>
        <ProfileSelf editable />
      </div>
    </div>
  );
}

interface IProfileModalProps extends IClassNameObj {
  uid?: TUserID;  // user id: if not specified, self
};
/**
 * Profile with a modal dialog
 */
export const ProfileModal: React.FC<IProfileModalProps> = ({ uid, className, children }) => {
  const state = useOverlayTriggerState({});
  const openBtnRef  = useRef<HTMLButtonElement>(null);
  const closeBtnRef = useRef<HTMLButtonElement>(null);

  // useButton ensures that focus management is handled correctly, across all browsers.
  // Focus is restored to the button once the dialog closes.
  const { buttonProps: openBtnProps  } = useButton( { onPress: () => state.open()  }, openBtnRef);
  const { buttonProps: closeBtnProps } = useButton( { onPress: () => state.close() }, closeBtnRef);
  return (
    <>
      <button ref={openBtnRef}
        {...openBtnProps}
        {...cLo(className, "focus:outline-none cursor-pointer")}
      >
        {children}
      </button>
      {state.isOpen &&
      <OverlayContainer>
        <ModalDialog
          title="Profile"
          clsDialog={cL("mx-8 lg:w-2/3 max-w-lg font-mono bg-gray-200 dark:bg-gray-700 text-black",
            "rounded-lg shadow-lg")}
          isOpen
          onClose={state.close}
          isDismissable
        >
          <div {...cLo("flex flex-col justify-center rounded-b-md",
              "dark:bg-gray-700 dark:text-gray-300")}
          >
            {uid
            ? <ProfileUserById {...{uid}} />
            : <ProfileSelf editable />
            }
            <button
              ref={closeBtnRef}
              {...closeBtnProps}
              {...cLo("p-1 bg-red-600 text-white rounded-b-lg shadow-lg",
                "hover:text-yellow-400 hover:bg-red-700")}
            >
              Close
            </button>
         </div>
        </ModalDialog>
      </OverlayContainer>}
    </>
  );
}

/**
 * User photos (background and avatar) portion of the profile
 */
const UserPhotoFrame = ({
  editable, backURL, photoURL, name
}: IEditable & Pick<IUserEx, 'backURL'|'photoURL'|'name'>) => {
  const twDnD = "relative flex-1 flex flex-col justify-center";
  const twAvatar = "mx-auto h-24 w-24 -my-12";
  const twAvatarBorder = "border-white shadow-md rounded-full overflow-hidden";
  return (
    <>
      <div {...cLo("pb-12 w-full justify-center items-center overflow-hidden")}>
        {editable
        ? <>
            <DnDOpenFile info
              url={backURL}
              {...cLo(twDnD, "h-52 sm:h-64 md:h-72 lg:h-96")}
              onFileCB={f => console.log(f?.preview)}
            />
            <DnDOpenFile
              url={photoURL || ANON_AVATAR}
              {...cLo(twDnD, twAvatar)}
              clsBorder={twAvatarBorder}
              clsButton="bottom-0 right-0"
              onFileCB={f => console.log(f?.preview)}
            />
          </>
        : <div {...cLo("flex flex-col justify-center mb-2")}>
            <img
              src={backURL || FALLBACK_BKG}
              {...cLo("w-full h-auto max-h-52 overflow-y-scroll object-cover")}
              alt="back"
            />
            <img
              src={photoURL || ANON_AVATAR}
              {...cLo(twAvatar, twAvatarBorder)}
              alt="avatar"
            />
          </div>
        }
      </div>
      <div {...cLo("font-semibold text-lg text-center")}>
        <DisplayName {...{name}} />
      </div>
    </>
  );
}

interface IUserDetail extends Pick<IUserEx, 'profile'|'group'|'city'|'email'|'emailV'|'env' > {
  createdAt?: TDateUMT;
  signInAt?:  TDateUMT;
  myself?:    boolean;
};
/**
 * user detail portion of the profile
 */
const UserDetail: React.FC<IUserDetail & IEditable> = ({
  children, myself, editable,
  profile, group, city, email, emailV, env,
  createdAt, signInAt
}) => {
  const [edit, setEdit] = useState(false);
  return (
    <div {...cLo("p-4")}>
      <div {...cLo("text-sm text-gray-700")}>
        <p {...cLo("py-1 border-b border-gray-400")}>{profile}</p>
        <ItemSvgIcon Path="email_pro">
          {email}
          <EmailVerified {...cLo("ml-1")} {...{emailV}} />
          {myself && !emailV && <ButtonEmailVerification />}
        </ItemSvgIcon>
        <ItemSvgIcon
          d={getPathD("people_back") +
             getPathD("people_front")}
          {...(!!editable && { onClick: () => setEdit(!edit) })}
        >
          {edit
          ? <input
              type="text"
              value={group}
              onChange={ev => console.log(ev.target.value)}
            />
          : group}
        </ItemSvgIcon>
        <ItemSvgIcon Path="pin_location">{city}</ItemSvgIcon>
      </div>
      <div {...cLo("font-mono bg-gray-100 mt-2")}>
        <div {...cLo("pl-1 text-xs bg-gray-600 text-gray-100")}>
          {env?.timeZone}
          <span {...cLo("pl-2 italic text-green-200")}>
            {env?.locale}
          </span>
        </div>
        <div {...cLoIf(createdAt, "pl-1 text-3xs bg-gray-200 text-red-700")}>
          created {timeUMTlocal(createdAt)}
        </div>
        <div {...cLoIf(signInAt, "pl-1 text-3xs bg-gray-100 text-indigo-500")}>
          sign-in {timeUMTlocal(signInAt)}
        </div>
        <div {...cLoIf(children, "mt-1 text-3xs text-gray-400")}>
          {children}
        </div>
      </div>
    </div>
  );
}

const ItemSvgIcon: React.FC< ISvgIconProps & Partial<IMouseProps> > = ({
  children,
  className = "w-6 h-6 fill-current mr-2",
  vLen = 512,
  onClick,
  ...props
}) =>
  <div {...cLo("flex items-center mt-1")}
    {...(onClick && {onClick})}
  >
    <SvgIcon {...{className, vLen}} {...props} />
    {children}
  </div>;

/**
 * Profile self
 */
export function ProfileSelf({ editable }: IEditable) {
  // Use firebase user info for self data intentionally.
  const cUser = currentUser();      // firebase user
  const ux = useUserById(cUser?.uid ?? '-1');  // extended user info
  if (!cUser)
    return null;
  // If the use is not registered yet, ux is null.
  const ux0 = {  // default properties for IUserEx
    group:   "<group>",
    city:    "<city>",
    profile: "<profile>",
    backURL: "osanbash.jpg",
  };
  const { group, city, profile, backURL, photoURL, name, email, emailV } = ux || ux0 as IUserEx;
  const { metadata } = cUser;
  return (
    <div {...cLo(twBgColor)}>
      <UserPhotoFrame {...{editable, backURL, photoURL, name}} />
      <UserDetail myself
        {...{editable, profile, group, city, email, emailV}}
        env={{
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          locale:   Intl.DateTimeFormat().resolvedOptions().locale,
        }}
        createdAt={metadata.creationTime}
        signInAt={metadata.lastSignInTime}
      >
        {cUser.providerData.map((pd, i) =>
          <ul key={i}>
            [Provider {i}] {pd?.providerId} / <DisplayName name={pd?.displayName} />
          </ul>
        )}
      </UserDetail>
      {!ux && <Register />}
    </div>
  );
}

/**
 * Profile by uid - readonly
 */
export function ProfileUserById({ uid }: { uid: TUserID; }) {
  const ux = useUserById(uid);  // extended user info
  if (!ux)
    return null;
  const { backURL, photoURL, name,
    profile, group, city, email, emailV, env,
    createdAt, signInAt } = ux;
  return (
    <div {...cLo(twBgColor)}>
      <UserPhotoFrame {...{backURL, photoURL, name}} />
      <UserDetail
        {...{profile, group, city, email, emailV, env,
          createdAt, signInAt}}
      />
    </div>
  );
}

const DisplayName = ({ name }: { name: Maybe<string> }) =>
  name
  ? <span {...cLo("text-gray-800")}>{name}</span>
  : <span {...cLo("italic text-red-400")}>No Name</span>;
