/*=============================================================================
 AuthForms.tsx - authentication forms including email login, etc.

 - used in AuthPage
 - [2021-02-27] react-aria button not works within <form> block
 - [2021-03-04] When the initial data loaded from the LocalStorage,
   background border color that depends on the score has no chance to be updated.
   Removed onChange hooking with setScore state;
   Just use value and apply scoreFunc(value) directly - much simpler

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useState, useCallback, } from 'react';
import { useAppDispatch } from 'app/store';
import { apiReq } from './authSlice';
import { cCo, cLo, cL, errorFmt } from 'utils/util';
import { RegEx_Auth } from 'utils/RegEx';
import { useLSEmailPwd, getLS, setLS, } from 'utils/useLocalStorage';
import { ToggleSlider } from 'ui/toggleSlider';
import { InputEmail, InputPassword, LabeledCheckbox } from 'ui/inputForms';
import { ErrorDlg } from 'ui/forms';
import { StepGuide }  from 'ui/StepGuide';
import { TermsOfUse } from 'ui/TermsOfUse';
import { Copyright }  from 'ui/ui';

/**
 * Switchable Signup or Login button by the checkbox
 */
const EmailForm = ({ onSignup, onStep, onError }: {
  onSignup:  (su: boolean)   => void;  // callbacks su: is signUp?
  onStep:    (st: number)    => void;
  onError:   (error: IError) => void;
}) => {
  const SIGNUP = 'signup';
  const [email,  setEmail]  = useState<string>("");
  const [pwd,    setPwd]    = useState<string>("");
  const [pwd2,   setPwd2]   = useState<string>("");
  const [save,   setSave]   = useState(false);  // Remember checkbox
  const [accept, setAccept] = useState(false);  // Accept Terms checkbox
  const [signup, setSignup] = useState(getLS(SIGNUP) === SIGNUP);
  const setSignupCB = useCallback((su: boolean) => {  // prevent changing on each render
    setSignup(su);
    onSignup(su);
    onStep(su ? (accept ? 1 : 0) : 0);
  }, [setSignup, onSignup, onStep, accept]);
  const dispatch = useAppDispatch();
  const updateLSEmailPwd = useLSEmailPwd(setSave, setEmail, setPwd);  // LocalStorage
  const toggleSignup = () => {
    const nextState = !signup;
    setSignupCB(nextState);
    setLS(SIGNUP, nextState ? SIGNUP : 'login');
  }

  const onFormSubmit: React.FormEventHandler = (ev) => {
    ev.preventDefault();  // to prevent page reload on form submit
    if ( !email
      || !pwd
      || !RegEx_Auth.email.test(email) )
    {
      onError(errorFmt('WARN', "Email Error", "Invalid email or password!"));
      return null;
    }
    if (signup) {
      if (pwd2 !== pwd) {
        onError(errorFmt('WARN', "Password Error", "Confirm password does not match!"));
        return null;
      }
      if (!accept) {  // Accept is required only when signing up.
        onError(errorFmt('WARN', "Accept Error", "You need to accept the Terms of Use!"));
        return null;
      }
    }
    updateLSEmailPwd(save, email, pwd);
    // ----------------------------------------------------------------------------------------
    // OK, proceed to signup of signin
    // ----------------------------------------------------------------------------------------
    dispatch( apiReq({ command: signup ? 'createUser' : 'signInEmail', email, pwd }) );
    // ----------------------------------------------------------------------------------------
  }
  type TChangeEventHandler = React.ChangeEventHandler<HTMLInputElement>;
  const handleChangeEmail:  TChangeEventHandler = (e) => { setEmail (e.target.value); }
  const handleChangePwd:    TChangeEventHandler = (e) => { setPwd   (e.target.value); }
  const handleChangePwd2:   TChangeEventHandler = (e) => { setPwd2  (e.target.value); }
  const handleChangeSave:   TChangeEventHandler = (e) => { setSave  (e.target.checked); }
  const handleChangeAccept: TChangeEventHandler = (e) => {
    setAccept(e.target.checked);
    onStep(signup && e.target.checked ? 1 : 0);
  }
  const twLabel = "text-sm dark:text-gray-100 text-gray-800";

  return (
    <form
      className="flex flex-col w-full max-w-sm mx-auto mt-1"
      onSubmit={ev => onFormSubmit(ev)}
    >
      <div className="flex flex-col font-mono">
        <span {...cLo(twLabel)}>Email address</span>
        <InputEmail    value={email} onChange={handleChangeEmail} />
        <span {...cLo(twLabel, "mt-4")}>Password</span>
        <InputPassword value={pwd}   onChange={handleChangePwd} />
        <InputPassword value={pwd2}  onChange={handleChangePwd2} show={signup} />
        <div className="my-4 flex justify-between">
          <LabeledCheckbox
            id="remember"
            checked={save}
            onChange={handleChangeSave}
          >
            <span {...cLo("ml-2 font-bold dark:text-gray-100 text-black")}>
              Remember
            </span>
          </LabeledCheckbox>
          <span {...cLo("flex items-center")}>
            <ToggleSlider
              isSelected={signup}
              onPress={toggleSignup}
              srOnly="Login or Signup"
              colors={signup ? "bg-red-300" : "bg-gray-200"}
              size="sm"
            />
            <span
              {...cCo("ml-1.5", signup,
              "dark:text-red-200 text-red-600",
              "dark:text-gray-200 text-gray-600 opacity-50")}
            >
              NEW User?
            </span>
          </span>
        </div>
        <button
          {...cCo(cL("flex-shrink-0 text-base font-bold py-2 px-4 text-white",
            "rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"),
            signup,
            "bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200",
            "bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200"
          )}
        >
          {signup ? "New Signup" : "Login"}
        </button>
      </div>

      <LabeledCheckbox
        id="accept-terms"
        checked={!signup || accept}
        disabled={!signup}
        className="my-3"
        onChange={handleChangeAccept}
      >
        <TermsOfUse accept={signup ? "accept" : "read"} />
      </LabeledCheckbox>
    </form>
  );
}

/**
 * Google SignIn
 */
const GoogleSignInButton = () => {
  const dispatch = useAppDispatch();
  return (
    <button
      type="button"
      {...cLo( "flex-shrink-0 bg-blue-700 text-white text-base font-semibold mt-2 py-2 px-4",
            "rounded-lg shadow-md hover:bg-blue-800 focus:outline-none focus:ring focus:ring-blue-500",
            "focus:ring-offset-2 focus:ring-offset-blue-200")}
      onClick={() => dispatch( apiReq({ command: 'signInGoogle' }) )}
    >
      <img
        className="absolute rounded-md -ml-3 -mt-2"
        width="40px" src="btn_google.svg" alt="google-icon"
      />
      Sign In with Google
    </button>
  );
}

/**
 * Auth Forms
 * To fix the sign-in/sign-up toggle button position, let StepGuide be 'absolute' position
 * - tailwind 'divide' not works here
 */
export const AuthForms = () => {
  const [signup, setSignup] = useState(false);   // callbacks to be set by EmailForm
  const [step,   setStep]   = useState(0);
  const [error,  setError]  = useState<TError0>(null);
  return (
    <>
      <div className="container px-1 py-10 mx-auto flex flex-wrap">
        <div className="relative flex flex-wrap w-full justify-center md:justify-end lg:mr-40">
          <StepGuide
            className="absolute left-0 hidden md:block pl-4 max-w-sm"
            mode={signup ? 'Signup' : 'Login'}
            step={step}
          />
          <div {...cLo("p-10 flex flex-row",
              "bg-gradient-to-b from-gray-50 to-gray-200 dark:from-gray-600 dark:to-black",
              "border-2 border-white dark:border-black rounded-lg shadow-lg")}
          >
            <div className="flex flex-col w-80 max-w-sm mx-auto mt-1">
              <p {...cLo("mb-8 text-2xl text-center dark:text-gray-200 text-gray-800")}>
                Sign in to your account
              </p>
              <EmailForm
                onSignup={setSignup}
                onStep={setStep}
                onError={setError}
              />
              <div className="border border-purple-200 my-2" />
              <GoogleSignInButton />
              <Copyright />
            </div>
          </div>
        </div>
      </div>
      {error && <ErrorDlg {...{error}} />}
    </>
  );
}
/*
      <button
        type="button"
        {...cloGoogleBtn}
        onClick={() => dispatch( apiReq({ command: 'signInGoogleR' }) )}
      >
        Sign In with Google - Redirection
      </button>

const AVATAR_PLACEHOLDER = "https://github.com/dashgon/dashgon.github.io/blob/master/public/shiba/shiba.png?raw=true";

// not used for now; when u need to add one more step before logging out
export const LogoutForm = ({ user }: { user: IUser }) => {
  const avatarUrl = (url: string0) => url || AVATAR_PLACEHOLDER;
  return (
    <div className="flex flex-col w-full max-w-sm mx-auto mt-1 space-x-3">
      <div className="flex flex-col">
        <div className="flex justify-center">
          <img src={avatarUrl(user.photoURL)} width="200" alt="profile" />
          {!user.photoURL &&
            <figcaption className="absolute text-lg">Register Your Profile</figcaption>
          }
        </div>
        <ul className="ml-8 font-semibold list-disc">
          <li>Email:<span className="ml-2 font-bold text-blue-800">{user.email}</span></li>
          <li>uid:<span className="ml-2 text-sm font-normal font-mono">{user.uid}</span></li>
          <li>name:<span className="ml-2 px-2 bg-yellow-200 rounded-lg">{user.name}</span></li>
        </ul>
        <div className="flex justify-center m-2">
          <LogoutButton className="w-full p-2">Logout</LogoutButton>
        </div>
      </div>
    </div>
  );
}
*/

/**
 * reset reducers
 * https://stackoverflow.com/questions/59061161/how-to-reset-state-of-redux-store-when-using-configurestore-from-reduxjs-toolki
 */
export const LogoutButton: React.FC< React.HTMLAttributes<HTMLButtonElement> > = ({
  className: cn, children, ...props
}) => {
  const dispatch = useAppDispatch();
  return (
    <button
      type="button"
      {...cLo("bg-red-100 hover:bg-red-700 font-bold text-black hover:text-white", cn)}
      onClick={(_e) => {
        dispatch( apiReq({ command: 'signOut' }) );
      }}
      {...props}
    >
      {children}
    </button>
  );
}

