/*=============================================================================
 Commands.tsx - Chat Commands

 (C) 2021 SpacetimeQ INC
=============================================================================*/

export const cmdCfg = {
  prefixes: [  // command sign at the start of input text
    ':',  // command
    '/'   // search
  ],
};

export const hasCommand = (text: string) =>
  text.length > 1 &&
  cmdCfg.prefixes.includes(text[0]);

export const runCommand = (text: string, ref: React.RefObject<HTMLDivElement>) => {
  const cmdType = text[0];
  const cmd = text.substring(1).toLowerCase();
  console.log(cmdType, cmd);
  if (cmd === 'today') {  // test
    const node = ref.current;
    if (node) {
      const date = new Date().toLocaleString();
      console.log(date);
      // node.textContent = date;
    }
  }
}
