/*=============================================================================
 Layout.tsx - Layout of the app

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { BrowserRouter, Switch, } from "react-router-dom";
import { ARoute, AuthRedirectToFrom, } from 'ui/layout/AuthRoute';
import { useAuthCtx }  from 'app/AuthContext';
import { RouteCompos } from './Routes';
import Navbar from './Navbar';

export default function Layout() {
  // const auth = useSelector(selectAuth);  // mystery: this call destroys auth reducer!
  const user = useAuthCtx().user;

  return (
    <BrowserRouter>
      <Navbar />
      <AuthRedirectToFrom isUser={!!user} />
      <Switch>
        {
          RouteCompos.map((rt, i) =>
            <ARoute key={i} authLv={user ? 1 : 0} {...rt} />)
        }
      </Switch>
    </BrowserRouter>
  );
}
