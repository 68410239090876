/*=============================================================================
 Bubble.tsx - Chat bubble

 - caller: ChatContent

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState } from 'react';
import { chatCfg } from '../Chat';
import { cLo, } from 'utils/util';
import { emailId } from 'utils/RegEx';
import { getUserById } from 'features/users/usersSlice';

interface IBubbleText extends IClassNameObj {
  text:    string;
  sender?: string;  // show sender id only if isNewId
};
/**
 * Chat Bubble
 * @param sender only when it changed
 * 1. Text over maxTextLen will be trimmed and expendable
 * - fold/unfold long text; keep and control its own state
 * 2. Multiline text will be parsed to <li>
 */
export const Bubble: React.FC<IBubbleText> = ({ text, sender, className, children }) => {
  const [fold, setFold] = useState(true);
  let trimSize = 0;  // if trimmed save the full size
  if (text.length > chatCfg.maxTextLen) {
    trimSize = text.length;
    if (fold)
      text = text.slice(0, chatCfg.maxTextLen);
  }
  // const aTxt = /\S/g.test(text)
  const aTxt = /\S/g.test(text)   // exported const RegEx_Util.no_whitespace not works
    ? text!.split('\\n').filter(w => w.length > 0)    // remove '' line
    : [];
  // let aTxt = text!.split('\\n').filter(w => w.length > 0)    // remove '' line
  return (aTxt.length || children || sender)
  ? <ul {...cLo(className, "overflow-hidden")}>
      {aTxt.map((txt, i) =>
        <li key={i} className="break-words">
          {i === 0 && sender && <SenderID uid={sender} />}
          {txt}
          {i === (aTxt.length - 1) && !!trimSize &&
            <span
              className="text-xs font-bold dark:text-blue-400 text-blue-600 cursor-pointer"
              onClick={() => setFold(!fold)}
            >
              {fold ? "... more" : " <<fold"} ({trimSize.toLocaleString()} bytes)
            </span>}
        </li>)}
      {!aTxt.length && sender && <SenderID uid={sender} />}
      {children}
    </ul>
  : null;
}

/**
 * sender changed from email to uid [2021-04-09]
 * Before introducing uniquely visible id, temporarily use 'name' or 'email' fields
 */
const SenderID = ({ uid }: { uid: TUserID }) => {
  const u = getUserById(uid);
  return (
    <span {...cLo("mb-2 mx-1 font-bold underline dark:text-blue-300 text-blue-700")}>
      {u
      ? u.name ? u.name.split(' ')[0] : emailId(u.email)
      : uid.substring(0,6) + '...'}
    </span>
  );
}

