/*=============================================================================
 ChatTime.tsx - Chat Time display

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { cCo, cLo, lZ } from 'utils/util';
import { isTodayDate } from 'utils/calendar';
import type { IChatProps } from 'features/chat/PaneChat';

interface IChatTimeProps extends IChatProps {
  date: string | number | Date;
};
/**
 * Chat message timestamp
 */
export const ChatTime: IFClassName<IChatTimeProps> = ({
  className, idSelf, isNewId, isNewDate, date
}) => {
  const dt   = new Date(date);  // JST=UTC+9
  const dtSp = '/';  // date separator
  const yyyymmdd = isNewDate || (isNewId && !isTodayDate(dt))
      ?    dt.getFullYear()   + dtSp +
        lZ(dt.getMonth() + 1) + dtSp +
        lZ(dt.getDate())
      : null;
  const tmSp = ':';  // time separator
  const hhmm = lZ(dt.getHours()) + tmSp +
               lZ(dt.getMinutes());
  const ss = tmSp + lZ(dt.getSeconds());
  return (
    <time
      {...cLo("flex flex-col mx-1", className)}
      dateTime={dt.toISOString()}
    >
      {yyyymmdd &&
        <span className="text-3xs dark:text-green-100 text-green-600">{yyyymmdd}</span>}
      <span
        {...cCo("flex flex-row items-end font-mono text-3xs", idSelf,
        "dark:text-pink-200 text-pink-800 justify-end",
        "dark:text-green-300 text-green-800")}
      >
        {hhmm}
        <span className="text-3xs italic">{ss}</span>
      </span>
    </time>
  );
}
