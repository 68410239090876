/*=============================================================================
 Navbar.tsx - Navigation Bar

 - fixed (position: fixed;) prevents the scrolling out of the top navbar
 - Sq_navbar-h (height: var(--navbar-h);) Navbar height

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useState } from 'react';
import { NavLink, } from 'react-router-dom';
import { RouteMenus, RouteMobileMenus, } from './Routes';
import { UserMenu, UserMenuListPulldown, } from './UserMenu';
import { cLo, cCo, cL, } from 'utils/util';
import { ToggleDarkButton } from 'ui/toggleDark';
import { twTextFocus, BurgerButton, } from 'ui/ui';
import Notis from 'features/notis/Notis';

// =========================================
// Tailwind Colorset Definitions
// =========================================
// colorset Background, Border, and Text
const twBgBorder = "dark:border-gray-600 dark:bg-gray-800 border-gray-800 bg-gray-200";
const twDtp = "hidden md:block";  // Desktop only
const twMbl = "md:hidden";        // Mobile only
// =========================================

/**
 * Using block and flex in the same <div> causes a newline effect,
 * thus use "hidden md:block" at the separate outer layer.
 */
const MainMenuList = ({ onMenuItemClick }: Partial<IMenuItemClickProps>) =>
  <>
    <div {...cLo(twDtp)}>
      <div {...cLo("flex flex-row font-medium")}>
        {RouteMenus.map(m =>
          <NavLink
            key={m.to}
            exact to={m.to}
            {...cLo("p-2 rounded-md opacity-70", twTextFocus)}
            activeClassName="underline opacity-100"
            onClick={onMenuItemClick}
          >
            {m.icon || m.title}
          </NavLink>)}
      </div>
    </div>
    <div {...cLo(twMbl, "flex w-full items-center justify-between")}>
      {RouteMobileMenus.map(m =>
        <NavLink
          key={m.to}
          exact to={m.to}
          aria-label={m.title}
          {...cLo("mr-2 xs:mr-3 opacity-70")}
          activeClassName="text-yellow-600 opacity-100"
          onClick={onMenuItemClick}
        >
          {m.icon}
        </NavLink>)}
    </div>
  </>;

/**
 * color doesn't change for the png file
 *
 * img src directory should start with '/' to prevent errors for routed pages
 */
const LogoButton: IFClassName<{ onPress: TButtonClickHandler }> = ({ className, onPress }) =>
  <button
    {...cLo(className, "p-0.5 mt-2 mr-1 border-2 border-transparent rounded-full",
      "bg-gray-800 hover:bg-gray-700 focus:bg-gray-700 focus:outline-none")}
    aria-label="Logo"
    onClick={onPress}
  >
    <img className="w-6 h-6" src="/logo.png" alt="" />
  </button>;

/**
 * https://tailwindcss.com/docs/responsive-design
 * Breakpoints (sm, md, lg, xl): takes effect at the specified bp and above
 */
const Navbar = () => {
  const [mblMenu, setMblMenu] = useState(false);  // is Mobile Menu On
  const onLogoClick = () => {
    console.log("Logo clicked!");
  }
  const onMenuItemClick = () => setMblMenu(false);
  return (
    <nav>
      <div {...cLo("fixed z-50 w-full flex flex-col shadow-lg border-b", twBgBorder)}>
        <div {...cLo("flex items-center justify-between Sq_navbar-h ml-10 mr-4")}>

          {/* Desktop/Mobile common part */}
          <div {...cLo("flex items-center dark:text-gray-200 text-gray-800")}>
            <LogoButton {...cLo("hidden md:block flex flex-shrink-0 mb-2")}
              onPress={onLogoClick}
            />
            <MainMenuList {...{onMenuItemClick}} />
          </div>

          <div {...cLo("flex ml-4 md:ml-6")}>
            {/* Desktop part */}
            <div {...cLo(twDtp)}>
              <div {...cLo("flex items-center")}>
                <ToggleDarkButton />
                <Notis />
                <UserMenu />
              </div>
            </div>
            {/* Mobile part */}
            <div {...cLo(twMbl, "flex p-1")}>
              <BurgerButton isSelected={mblMenu} onPress={() => setMblMenu(!mblMenu)} />
            </div>
          </div>

        </div>
      </div>

      {/* Mobile Pulldown menu */}
      <div {...cCo(cL(twMbl, twBgBorder, "fixed z-40 w-full shadow-2xl Drawer_transition pt-10"),
          mblMenu, 'block translate-y-0', '-translate-y-full pointer-events-none')}
      >
        <UserMenuListPulldown {...{onMenuItemClick}}>
          <ToggleDarkButton />
          <Notis />
        </UserMenuListPulldown>
      </div>
      {/* To push down page offset */}
      <div className="Sq_navbar-h" />
    </nav>
  );
}

export default Navbar;
