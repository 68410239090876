/*=============================================================================
 Image.tsx - Lazy img loading with error handling

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useEffect, useState } from "react";

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  placeholderImg?: string;
  errorImg?:       string;
}
/**
 * Lazy img load to handle error
 * @param placeholderImg will be shown before src load
 * @param errorImg will replace src in case of resulting error
 * - alt prop brought out explicitly just to prevent compiler warning
 */
export const LImg = ({ src, placeholderImg, errorImg, alt, ...props }: ImageProps) => {
  const [imgSrc, setSrc] = useState(placeholderImg || src);  // priority on the placeholderImg
  useEffect(() => {
    if (src) {
      const EV_LOAD  = "load";
      const EV_ERROR = "error";
      const handleImgLoad  = () => { setSrc(src); }
      const handleImgError = () => { setSrc(errorImg || placeholderImg); }
      const img = new Image();
      img.src = src as string;
      img.addEventListener(EV_LOAD,  handleImgLoad);
      img.addEventListener(EV_ERROR, handleImgError);
      return () => {
        img.removeEventListener(EV_LOAD,  handleImgLoad);
        img.removeEventListener(EV_ERROR, handleImgError);
      }
    } else {  // src is null or undefined cases
      setSrc(errorImg || placeholderImg);
    }
  }, [src, placeholderImg, errorImg]);
  return <img {...props} src={imgSrc} {...{alt}} />;
};
