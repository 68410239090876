/*=============================================================================
 chat/PaneChat.tsx - Chat Main Window

 - PATTERN: ChatReactions was exposed here to pass over click handlers:
   Previously it was hidden under ChatContent: now pass ChatReactions as a child.

 - 2021.08.26 refFO (ref to FileOpen button) was added to access a hidden button
   at the same level from ChatInput. When the picture button on ChatInput is pressed,
   using refFO Dropzone's children's hidden button that can call the parent's open function.

 - Image Gallery in a room: keep a list of all image or movie urls and show in gallery mode.
 - Chat on media (annotation) with reference link and location

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useRef, useEffect, useState, } from 'react';
import { useAuthCtx }     from 'app/AuthContext';
import { useAppDispatch } from 'app/store';
import { useSelector }    from 'react-redux';
import { selectAllMsgs, addMsg, updateMsg, msgExitRoom, } from 'features/msgs/msgsSlice';
import { getUserOrder, } from 'features/rooms/roomsSlice';
import type { IRoom } from 'models';
import { avatarBdrClr, AvatarImgByUid, } from 'ui/Avatars';
import { FALLBACK_BKG } from 'app/res';
import { cLo, cC, cCo, } from 'utils/util';
import { sameDate } from 'utils/calendar';
import { RegEx_Cont, } from 'utils/RegEx';
import ChatParse from './cont/ChatParse';
import { ChatTime } from './cont/ChatTime';
import { ChatReactions, } from './cont/Reactions';
import type { IReactionOnMsg, } from './cont/Reactions';
import ChatInputControl from './input/ChatInput';
import { runCommand, hasCommand } from './input/Commands';
import { ToggleButtonSvg, } from 'ui/ui';
import { SearchBoxForm, } from 'ui/SearchBox';
import { PopupMenu, MenuItemExit } from 'ui/menus';
import { LImg } from 'utils/Image';
import type { IFilePreview, } from 'utils/DnDOpenFile';
import { DnDWrapper, } from 'utils/DnDOpenFile';
import { uploadFile, } from 'api/storageAPI';

export interface IChatProps {
  idSelf?:    boolean;  // sender is self
  isNewId?:   boolean;  // if the sender is different from the previous
  isNewDate?: boolean;  // if the date changed between chat messages
  bdrClr?:    string    // border color class
};

/**
 * Chat head menu bar
 */
const ChatHead: React.FC<{
  nMsgs: number;
  room:  Readonly<IRoom>;
}> = ({ nMsgs, room, children }) =>
  <div {...cLo("flex flex-row items-center justify-between flex-none pr-2 shadow-md border",
        "dark:border-gray-600 dark:bg-gray-800 border-gray-400 bg-gray-300")}
  >
    <div className="flex">
      <LImg
        {...cLo("object-cover h-10")}
        src={room.backURL}
        errorImg={FALLBACK_BKG}
        alt="room icon"
      />
      <div className="pl-2 text-sm hidden sm:block">
        <p className="font-bold">{room.title}</p>
        <p className="text-xs">
          <span {...cLo("font-bold dark:text-yellow-400 text-yellow-700")}>
            {room.users.length}
          </span> users
          <span {...cLo("font-bold dark:text-blue-400 text-blue-700 ml-1")}>
            {nMsgs}
          </span> messages
        </p>
      </div>
    </div>
    <div className="flex flex-row">
      {children}
    </div>
 </div>;

/**
 * all tags in a text returned in array
 */
const tagsArray = (text: string, re: RegExp): string[] | undefined => {
  const hashtags = text.match(re);
  if (!hashtags)
    return undefined;
  hashtags.map(t => t.substring(1));  // remove the prefix tag
  return hashtags;
}

/**
 * Chat pane with a chat head, a chat content list, and a chat input window.
 */
export default function PaneChat ({ room, showRoomsCB }: {
  readonly room: IRoom;
  showRoomsCB?:  () => void;
}) {
  const ref    = useRef<HTMLDivElement>(null);  // for scroll control
  const refCI  = useRef<HTMLDivElement>(null);  // to take control of ChatInputControl
  const refFO  = useRef<HTMLButtonElement>(null);  // to access the hidden File Open button
  const msgs   = useSelector(selectAllMsgs);
  const sender = useAuthCtx().user!.uid;        // changed from email to uid
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');  // search text

  const scrollToBottom = () => {
    const node = ref.current;
    if (node)
      node.scrollTop = node.scrollHeight;
  }

  useEffect(() => {
    scrollToBottom();
  }); //, []);  // Run only initially once

  if (!sender) {
    alert("PaneChat:No sender!");
  }

  const onNewMsg = (text: string) => {
    //-------------------------------------------------------------------------
    // 1. Check for the Command mode
    //-------------------------------------------------------------------------
    if (hasCommand(text)) {
      runCommand(text, refCI);
      return;
    }
    //-------------------------------------------------------------------------
    // 2. Parse hashtags, usernames,
    //-------------------------------------------------------------------------
    const hashtags  = tagsArray(text, RegEx_Cont.hashtag);
    const usernames = tagsArray(text, RegEx_Cont.username);
    //-------------------------------------------------------------------------
    // 3. Send message
    //-------------------------------------------------------------------------
    dispatch( addMsg({  // IMsgCreate
      roomId: room.id,
      sender,
      text,
      ...(hashtags  && { hashtags }),
      ...(usernames && { usernames }),
    }) );  // returns TMsgID
  }

  const reactionCB = (react: IReactionOnMsg) => {
    switch (react.reaction) {
      case 'DELETED':
        dispatch( updateMsg({  // IMsgUp
          roomId: room.id,
          id:     react.id,
          hide:   react.reaction,  // THideMsgReason
        }) );
        break;
      case 'REPLY':
        console.log("REPLY");
        break;
      case 'LIKE':
        console.log("LIKE");
        break;
      default:
    }
  }

  const onFileCB = async (file: Undefinable< IFilePreview >) => {
    let imgUrl = undefined;
    if (file) {
      try {
        imgUrl = await uploadFile(sender, file);
        console.log("DownloadURL:", imgUrl);
      } catch (error) {
        console.error("File Upload", error);
        return null;
      }
    }
    if (imgUrl) {
      dispatch( addMsg({  // IMsgCreate
        roomId: room.id,
        sender,
        img: imgUrl,
      }) );  // returns TMsgID
    }
  }

  let idPrev: string0 = null;
  let tmPrev = 0;  // time in UTC ms

  // test: msgs.filter(a => a && a.text && a.text.length > 6)
  // onClearCB={() => setSearchText('')}
  return (
    <>
      <ChatHead nMsgs={msgs.length} {...{room}}>
        <SearchBoxForm
          {...cLo("py-0.5 pl-8")}
          {...{setSearchText}}
          placeholder="ENTER Search Text"
        />
        <ToggleButtonSvg
          Path ="double_down"
          Path2="double_up"
          onPress={showRoomsCB}
        />
        <PopupMenu
          title={room.title}
          menuItems={[
            {
              item:     <MenuItemExit text="Exit Channel" />,
              disabled: !room,
              onClick:  () => dispatch( msgExitRoom() ),
              classX:   "border-t border-gray-500",
            },
          ]}
          Path="dots_vertical"
        />
      </ChatHead>
      <DnDWrapper {...{ref, onFileCB}}
        {...cLo("sm:w-full flex-1 overflow-y-scroll overflow-x-hidden",
          "px-1 border-r border-gray-400")}
      >
        {onFileOpen =>
          <>
            <button ref={refFO} {...cLo("hidden")} onClick={onFileOpen} />
            {room.backURL &&
            <LImg
              {...cLo("w-full")}
              src={room.backURL}
              errorImg={FALLBACK_BKG}
              alt="backdrop"
            />}
            {msgs.map(m => {
              if (searchText && !m.text?.includes(searchText))
                return null;
              const idSelf  = m.sender === sender;
              const isNewId = m.sender !== idPrev;
              if (isNewId)
                idPrev = m.sender;  // save the current id to check the changes at the next loop
              const isNewDate = !sameDate(m.time, tmPrev);
              if (isNewDate)
                tmPrev = m.time;
              const bdrClr = avatarBdrClr(getUserOrder(m.sender));
              return (
                <div key={m.id} {...cLo(idSelf ? "Chat_right" : "Chat_left", isNewId && 'mt-1')}>
                  {!idSelf && isNewId &&
                    <div {...cLo("mt-1 mr-1")}>
                      <AvatarImgByUid uid={m.sender} {...{bdrClr}} />
                    </div>}

                  <div {...cLo("Chat_cont")}>
                    <div {...cCo(cC("group flex items-center", m.hide, "opacity-50"),
                      idSelf, "flex-row-reverse")}
                    >
                      {m.hide
                      ? <div {...cLo("mx-1 text-xs italic text-red-400")}>{m.hide}</div>
                      : <ChatParse {...{m, idSelf, isNewId, bdrClr}} />}
                      <ChatTime
                        {...{idSelf, isNewId, isNewDate}}
                        date={m.time}
                      />
                      {!m.hide &&
                        <ChatReactions {...{id: m.id, idSelf, reactionCB}} />}
                    </div>
                  </div>
                </div>
              );
            })}
          </>}
      </DnDWrapper>
      <ChatInputControl ref={refCI}
        onSubmit={onNewMsg}
        onFileClick={() => refFO?.current?.click()}
      >
      </ChatInputControl>
    </>
  );
}
  // <a href={url} target="_blank" rel="noreferrer">{url}</a>
              // <ChatContent {...{m, idSelf, isNewId, isNewDate, bdrClr}}>
              //   <ChatReactions {...{id: m.id, idSelf, reactionCB}} />
              // </ChatContent>
