/*=============================================================================
 PaneDetails.tsx - Pane Details (at the right pane)

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
import { useState, useEffect, } from 'react';
import { useSelector } from 'react-redux';
import { cLo, } from 'utils/util';
import { PaneRoomUsers, } from './PaneUsers';
import { ProfileSelf, ProfileUserById } from 'features/profile/ProfilePage';
import { useCurrentUser }  from 'features/users/usersSlice';
import { useRoomById, } from 'features/rooms/roomsSlice';
import { selectRoomId, } from 'features/msgs/msgsSlice';

interface IPaneDetailsProps extends IClassNameObj {
  onRoomChange?: (nextShow: boolean) => void;
};
/**
 * Room Users List and the User Profile
 */
export default function PaneDetails({ className }: IPaneDetailsProps) {
  // use Redux Hooks to be rendered on room changes
  const roomId = useSelector( selectRoomId );
  // console.log("PaneDetails:roomId=", roomId);
  const room   = useRoomById(roomId);
  const cUid   = useCurrentUser()?.uid;
  const [uid, setUid] = useState< Undefinable<TUserID> >(room?.users[0]);
  // useState initial value will work only for the initial render.
  // To reflect the change of room and room members, we need useEffect.
  useEffect(() => {
    setUid(room?.users[0]);
    console.log("PaneDetails:", room?.users[0]);
    // onRoomChange(true);  // true: give a chance to pushMain on window size change
  }, [room]);
  return (
    <div {...cLo(className, "flex-1 Sq_h-members overflow-y-scroll")}>
      {uid
        ? (uid === cUid)
          ? <ProfileSelf />
          : <ProfileUserById {...{uid}} />
        : <ProfileSelf editable />}
      {!!room && <PaneRoomUsers {...{room}} clickUserCB={id => setUid(id)} />}
    </div>
  );
}

