/*=============================================================================
 TabsList.tsx - Tabs lit UI

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, } from 'react';
import { cC, cLo, } from 'utils/util';

interface ITabItem {
  title:   React.ReactNode;
  content: React.ReactNode;
};
/**
 * Tabs list
 */
export const TabsList: React.FC<{ items: ITabItem[] }> = ({ items, children }) => {
  const [tab, setTab] = useState<number>(0);
  const twTab = (sel: typeof tab) =>  // selected Tab
    cC("ml-1 px-1 py-0.5 rounded-t-lg opacity-70 hover:opacity-100 border-r-2 border-gray-500",
      tab === sel,
      "bg-gray-600 text-gray-50",
      "bg-gray-300 text-gray-500");
  return (
    <>
      <div {...cLo("flex flex-row justify-start items-end font-mono text-sm cursor-pointer pt-1",
          "border-b-2 border-gray-600 border-opacity-70")}
      >
        {items.map((it, i) =>
          <div key={i} {...cLo(twTab(i))} onClick={() => setTab(i)}>{it.title}</div>)}
      </div>
      {(tab < items.length) && items[tab].content}
      {children}
    </>
  );
}
