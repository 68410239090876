/*=============================================================================
 firebase.ts - Firebase access


 (C) 2020 SpacetimeQ INC.
=============================================================================*/
// May 27, 2020 by Soomin K.
// import * as firebase from "firebase/app";
import firebase from "firebase/app";  // after 8.0.0
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {  // Load from .env (restart yarn for changes)
  apiKey:            process.env.REACT_APP_API_KEY,
  authDomain:        process.env.REACT_APP_AUTH_DOMAIN,
  projectId:         process.env.REACT_APP_PROJECT_ID,
  storageBucket:     process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId:             process.env.REACT_APP_ID,
  measurementId:     process.env.REACT_APP_MEASUREMENT_ID
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}
if (!process.env.NODE_ENV ||
     process.env.NODE_ENV === 'development') {
  const USE_EMULATORS = true;
  if (USE_EMULATORS) {
    firebase.auth().useEmulator("http://localhost:9099");
    firebase.firestore().useEmulator('localhost', 8080);
    firebase.storage().useEmulator('localhost', 9199);
    firebase.functions().useEmulator('localhost', 5001);
  }
  console.log("firebase:", firebase.app().name, firebase.SDK_VERSION);  // [DEFAULT]
  // const el = document.getElementsByClassName('firebase-emulator-warning')[0];
  // el?.setAttribute('style', "opacity: 0.2;");
  // console.log(process.env.NODE_ENV);  // development
  // console.log(process.env.REACT_APP_API_KEY);
}

export default firebase;
/*
 * Test accounts:
 * soomin999@gmail.com/!139Ocean (Email auth)
 * soomin@spacetimeq.com/!139Ocean (Email auth)
 */
