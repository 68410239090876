/*=============================================================================
 DnDOpenFile.tsx - Drop or open images to upload

 - react-dropzone: https://react-dropzone.js.org/

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useEffect, useState, useCallback, forwardRef, } from 'react';
import { cS, cLo, cLoIf, } from 'utils/util';
import { SvgIcon, } from 'utils/svg';
import type { TPathDataKey } from 'utils/svg';
// react-dropzone --------------------------------------------------------------------------------
import { useDropzone, } from 'react-dropzone';
import type { FileWithPath, FileRejection, DropEvent } from 'react-dropzone';
// react-dropzone --------------------------------------------------------------------------------

/**
 * Blob: size, type, ...
 * - DOMFile: lastModified, name
 * - FileWithPath: path
 * - IFilePreview: preview
 */
export interface IFilePreview extends FileWithPath {
  preview: string;
};

/**
 * export interface IDnDFileInfo extends Pick<IFilePreview, 'path'|'preview'|'size'> { };
 */
type TOnDrop = <T extends File>(  // a property of type DropzoneOptions
  acceptedFiles:  T[],
  fileRejections: FileRejection[],
  event:          DropEvent
) => void;

//------------------------------------------------------------------------------
// File drop and change the background image
//------------------------------------------------------------------------------
interface IOpenFileButtonProps extends Partial<IButtonProps>, IClassNameObj {
  Path?:   TPathDataKey;
};
/**
 * camera icon to upload a new image file or a url
 * container block should be 'relative'
 */
const OpenFileButton = ({
    className = "top-2 right-2",
    Path      = "camera_pic",
    onClick,
}: IOpenFileButtonProps) => {
  return (
    <button
      type="button"
      {...cLo("absolute opacity-50 cursor-pointer hover:opacity-100 focus:outline-none",
          className)}
      {...{onClick}}
    >
      <SvgIcon {...{Path}} stroke="darkgreen" fill="white" />
    </button>
  );
}

/**
 * DnD file info (path and size)
 * @param blob: true for the blob url (preview)
 */
export const strDnDInfo = (f: Undefinable<IFilePreview>, blob?: boolean) => f
  ? cS(f.path, ' (', f.size.toLocaleString(), ' bytes)',
      !!blob && ('->' + f.preview))
  : null;

const ListAcceptedFiles = ({ acceptedFiles }: { acceptedFiles: FileWithPath[] }) =>
  <ul>
    {acceptedFiles.map(f => <li key={f.path}>{f.path} ({f.size.toLocaleString()} bytes)</li>)}
  </ul>;

const ListRejectedFiles = ({ fileRejections }: { fileRejections: FileRejection[] }) =>
  <ul>
    {fileRejections.map(({ file, errors }) => {
      const { path, size } = file as FileWithPath;
      return (
        <li key={path}>
          <span className="ml-1 text-3xs dark:text-pink-300 text-pink-600">
            {errors.map((e, i) =>
              <span key={i} className="mr-2">
                <span className="text-3xs dark:text-red-300 text-red-800 mr-1">{e.code}</span>
                {e.message}
              </span>)}
          </span>
          {path}({size.toLocaleString()} bytes)
        </li>
      );
    })}
  </ul>;

/**
 * DnD - Drag & Drop zone wrapper: minimum props
 *
 * @param onFileOpen will be passed to children so that it can be called back
 */
interface IDnDWrapperProps extends IClassNameObj {
  accept?:   string;                         // file types to accept
  onFileCB?: (file?: IFilePreview) => void;  // callback on File load
  children?: (onFileOpen: () => void) => React.ReactNode;  // render prop for DnD open
};
/**
 * @param url for the initial and fallback img
 */
interface IDnDOpenFileProps extends IDnDWrapperProps {
  url?:       Maybe<string>;  // initial and fallback url
  urlFirst?:  boolean;        // use URL first (to have priority over DnD image)
  info?:      boolean;        // to show file info?
  clsBorder?: TClassName;     // tailwind classes Border
  clsButton?: TClassName;     // tailwind classes Button
};
/**
 * Drag and Drop Open File
 * @param url if url is set, ignore dropped file in preview
 * @param onFileCB (file?: IFilePreview) => void;
 */
export const DnDOpenFile = ({
  accept = 'image/*',
  url, urlFirst, className, clsBorder, clsButton,
  info, onFileCB
}: IDnDOpenFileProps) => {
  const [files,  setFiles]  = useState<IFilePreview[]>([]);
  const [errors, setErrors] = useState(false);

  const onDrop: TOnDrop = useCallback(acceptedFiles => {
    const hasErrors = !acceptedFiles.length;
    setErrors(hasErrors);
    if (!hasErrors) {
      setFiles(acceptedFiles.map(f =>
        Object.assign(f, { preview: URL.createObjectURL(f) }) )
      );
    }
    onFileCB?.(acceptedFiles[0] as unknown as IFilePreview);
  }, [onFileCB]);

  const {
    getRootProps, getInputProps, open,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles, fileRejections
  } = useDropzone({
    noClick:    true,
    noKeyboard: true,
    multiple:   false,  // only one file is allowed to be picked
    accept,
    maxSize:    10*1024*1024,  // maximum allowed filesize in bytes
    onDrop
  });

  useEffect(() => {
    return () => {
      files.forEach(f => URL.revokeObjectURL(f.preview));  // cleanup
    }
  }, [files]);

  const imgSrc = urlFirst
    ? url
    : (files[0]?.preview || url);

  return (
    <div {...getRootProps({...{className}})}>
      <div {...cLo("border-2", clsBorder,
        isDragActive && "border-dashed",
        isDragAccept && "border-green-400",
        isDragReject && "border-red-400",
        (!files[0] && !url) && "border-dashed border-gray-300 outline-none")}
      >
        {imgSrc
        ? <img {...cLo("w-full object-cover")}
            src={imgSrc}
            alt="invalid URL"
          />
        : <p {...cLo("m-8 text-center text-xs text-gray-500 italic text-opacity-50 truncate")}>
            Drag and drop an image file.
          </p>}
      </div>
      <input {...getInputProps()} />
      <OpenFileButton onClick={open} {...cLo(clsButton)} />
      <div {...cLoIf(info, "absolute left-2 top-0 text-3xs truncate",
        "bg-gray-300 bg-opacity-50 rounded-sm text-black")}
      >
        {errors
        ? <ListRejectedFiles {...{fileRejections}} />
        : <ListAcceptedFiles {...{acceptedFiles}} />}
      </div>
    </div>
  );
}

/**
 * DnD file drop zone
 *
 * pass open fuction to children as a render prop
 * @param onFileCB called back when files are dropped
 * @param children will be called with open(), so that the children can call the parent's
 *   file open dialog. (render prop)
 */
export const DnDWrapper =
  forwardRef<HTMLDivElement, IDnDWrapperProps >(
  ({
    className,
    accept = 'image/*',
    onFileCB,
    children
  }, ref) =>
{
  const [files, setFiles] = useState<IFilePreview[]>([]);

  const onDrop: TOnDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length) {
      setFiles(acceptedFiles.map(f =>
        Object.assign(f, { preview: URL.createObjectURL(f) }) )
      );
    }
    onFileCB?.(acceptedFiles[0] as unknown as IFilePreview);
  }, [onFileCB]);

  const {
    getRootProps, getInputProps, open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick:    true,
    noKeyboard: true,
    multiple:   false,  // only one file is allowed to be picked
    accept,
    maxSize:    10*1024*1024,  // maximum allowed filesize in bytes
    onDrop
  });

  useEffect(() => {
    return () => {
      files.forEach(f => URL.revokeObjectURL(f.preview));  // cleanup
    }
  }, [files]);

  return (
    <div {...getRootProps({...{ref, className}})}>
      <div {...cLo("border-2 dark:border-gray-700 border-gray-200",
        isDragActive && "border-dashed",
        isDragAccept && "border-green-400",
        isDragReject && "border-red-400")}
      >
        {children?.(() => open())}
      </div>
      <input {...getInputProps()} />
    </div>
  );
});
