/*=============================================================================
 toggleDark.tsx - description

 - Removed useState from the button, let the parent keep and handle the state:
   stateless buttons
 - Just propagate onClick event, a callback from the parent
 - using React ContextAPI, let the <ToggleDarkButton> be active in itself
 - 2021/05/27 removed linearGradient since it didn't work for unknown reason

 (C) 2020 SpacetimeQ INC
=============================================================================*/
import { useDarkThemeCtx } from './darkThemeCtx';
import { cL, cLo } from 'utils/util';
import { squareView, SvgIconFull, } from 'utils/svg';
import { ToggleDot, ToggleSlider }  from 'ui/toggleSlider';
import type { IToggleSliderProps, } from 'ui/toggleSlider';
import { TooltipSpan } from 'ui/Tooltip';

/**
 * Left Button switch to enable/disable "media" mode (that prefers media)
 * ToggleSlider to force "dark"/"light" mode
 */
export const ToggleDarkButton: IFClassName< Pick<IToggleSliderProps, 'colors'|'size'> > = ({
  className: cn,
  colors    = "bg-gray-500",
  size      = 'md',
}) => {
  const {
    dark,   // is dark mode?
    media,  // prefers media mode?
    night,  // is it night? (in prefers media mode)
    toggle  // toggle dark callback
  } = useDarkThemeCtx();  // checks both LocalStorage.theme and prefers-color-scheme
  const len = {  // sun and moon icon sizes
    sm: { light: 20, dark: 16 },
    md: { light: 24, dark: 20 },
    lg: { light: 28, dark: 24 },
  };
  return (
    <span {...cLo("flex items-center", cn)}>
      <TooltipSpan
        {...cLo("flex justify-center")}
        clsPos="z-50 inset-y-0 right-0 mt-6 p-1"
        clsSize="w-40 h-16 font-normal text-sm"
        tip="Darkmode: Unset to use device default"
      >
        <ToggleDot
          bgcolors={{
            on: "bg-gray-200",
            off: night ? "bg-red-500" : "bg-green-400"
          }}
          isSelected={media}
          onPress={() => toggle(media)}
        />
      </TooltipSpan>
      <ToggleSlider
        isDisabled={media}
        isSelected={dark}
        onPress={() => toggle()}
        srOnly="Dark mode switch"
        colors={media ? cL(colors, "opacity-50") : colors}
        size={size}
      >
        <SvgIconFull
          {...cLo("mx-0.5 dark:text-yellow-200 text-gray-500")}
          {...squareView(len[size].dark)}
          Path="moon_dark"
          fill="currentColor"
        />
        <SvgIconFull
          {...cLo("mx-0.5 text-yellow-300 dark:text-gray-500")}
          {...squareView(len[size].light, 32)}
          Path="sun_light"
          fill="currentColor"
        />
      </ToggleSlider>
    </span>
  );
}

// button - needs customization for the following non-default tailwind classes
//  focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white focus-visible:ring-gray-500
//  button style={{backgroundColor: dark ? "rgb(17,24,39)" : "rgb(96,211,96)"}}
//
// For some reason, not working after 6a12a5e
// <svg {...squareView(len[size].light, 32)} fill="none">
//   <defs>
//     <linearGradient id="lG_light" x1="2" y1="2" x2="30" y2="30" gradientUnits="userSpaceOnUse">
//       <stop className={stopCls} stopColor={dark ? "#d4d4d8" : "#FACC15"} />
//       <stop className={stopCls} stopColor={dark ? "#d4d4d8" : "#FA9D16"} offset="1" />
//     </linearGradient>
//   </defs>
//   <path {...RULE_EVENODD} fill="url(#lG_light)" d={getPathD("sun_light")} />
// </svg>

