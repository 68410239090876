/*=============================================================================
 Setting.tsx - User settings

 (C) 2020 SpacetimeQ INC.
=============================================================================*/

export default function SettingPage() {
  return (
    <div className="text-center mt-10">
      <h1 className="dark:text-yellow-200 text-yellow-700">Under Construction...</h1>
    </div>
  );
}
