/*=============================================================================
 chat/PaneUsers.tsx - Left pane for search/room members/contacts

 - ROOM MEMBERS : Active users in the selected room
   rooms/{roomId}.users   *subscribe to rooms/{roomId} doc

 - CONTACTS        : Friend list
   users/{uid}.contacts    *subscribe to users/{uid} doc

 (C) 2020 SpacetimeQ INC
=============================================================================*/
import { useState, useEffect, } from 'react';
import { avatarBdrClr, } from 'ui/Avatars';
import { cLo, showIf, } from 'utils/util';
import { useAppDispatch } from 'app/store';
import { fetchUsers, }   from 'api/fetchData';
import { useCurrentUser, } from 'features/users/usersSlice';
import { UserInfo, UsersList, } from 'features/users/UserInfo';
import type { IClickUserCallback, } from 'features/users/UserInfo';
import { getUserOrder, } from 'features/rooms/roomsSlice';
import { msgExitRoom, }  from 'features/msgs/msgsSlice';
import type { IRoom, } from 'models';
import { PaneHead, PopupMenu, MenuItemExit } from 'ui/menus';
import { AddContactModal } from './AddContacts';

// This pane's visibility is controlled at the outer layer.
// Here, part of the columns need to be responsive to the width of the pane.
// const twOverMed = "hidden md:block";  // show only from md for responsive mode

//-----------------------------------------------------------------------------
// CHANNEL MEMBERS - subscribe to the room doc
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
// Users List main
//-----------------------------------------------------------------------------
interface IPaneUsersProps extends IClassNameObj, IClickUserCallback {};
/**
 * Room (channel) users list with a header
 */
export function PaneRoomUsers({
  room, className, clickUserCB,
}: { room?: IRoom; } & IPaneUsersProps) {
  const dispatch = useAppDispatch();
  // const user = useCurrentUser();
  const [active, setActive] = useState(false);  // show active room members only
  const [selIdx, setSelIdx] = useState<number>(0);
  const title = "CHANNEL MEMBERS";
  useEffect(() => {
    setSelIdx(0);
  }, [room]);
  return (
    <div {...{className}}>
      <PaneHead
        title={room?.title || title}
        iconPath="bubble_comment"
      >
        <PopupMenu
          {...{title}}
          menuItems={[
            {
              item:     `Show ${active ? "All" : "Active Only"}`,
              disabled: !room,
              onClick:  () => setActive(!active)
            },
            {
              item:     <MenuItemExit text="Exit Channel" />,
              disabled: !room,
              onClick:  () => dispatch( msgExitRoom() ),
              classX:   "border-dotted border-t-2 border-gray-500",
            },
          ]}
          Path="dots_vertical"
        />
      </PaneHead>
      <UsersList emptyMsg="No current channel">
        {room?.users.map((uid, i) => {
          const on = room.users.includes(uid);
          return (
            <UserInfo key={uid}
              {...{uid, on}}
              clickUserCB={id => { clickUserCB?.(id); setSelIdx(i); }}
              selected={i === selIdx}
              bdrClr={avatarBdrClr(getUserOrder(uid))}
              {...cLo(showIf(!active || on))}
            />
          );
        })}
      </UsersList>
    </div>
  );
}

/**
 * Contacts list
 */
export function PaneContacts({ className }: IPaneUsersProps) {
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  fetchUsers(dispatch, user?.contacts);
  return (
    <div {...cLo(className)}>
      <PaneHead
        title={`CONTACTS(${user?.contacts?.length || 0})`}
        iconPath="users_group"
      >
        <AddContactModal clsButton="pr-1" />
      </PaneHead>
      <div {...cLo("Sq_h-contacts overflow-y-scroll")}>
        <UsersList emptyMsg="No contacts">
          {user?.contacts?.map(uid =>
            <UserInfo key={uid} contacts
              {...{uid}}
              bdrClr="border-gray-400"
            />)}
        </UsersList>
        <div {...cLo("mt-4 text-9xl text-center opacity-30")}>🌏</div>
      </div>
    </div>
  );
}

