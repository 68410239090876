/*=============================================================================
 ModalDlgTw.tsx - Modal dialog to confirm using tailwindcss

 - Check z-order: z-50

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { Fragment, useRef, } from 'react';
import { cLo, } from 'utils/util';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';

export interface IModalDlgText {
  checkmark: boolean;
  title:     string;
  ok:        string;
  cancel:    string;
};
interface IModalDlgProps extends IModalDlgStates, Partial<IModalDlgText> {};
/**
 * Providing content as children, using React component and accessible all the parent states
 */
export const ModalDlg: React.FC<IModalDlgProps> = ({
  open,
  setOpen, onOkCancel,  // callbacks
  title, ok, cancel,    // IModalDlgText
  checkmark = false,
  children              // content
}) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const handleClick = (ok: boolean) => {
    setOpen(false);
    onOkCancel?.(ok);
  }
              //  sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        {...cLo("z-50 fixed inset-0 overflow-y-auto")}
        initialFocus={btnRef}
        open={open}
        onClose={setOpen}
      >
        <div {...cLo("flex items-end justify-center min-h-screen",
          "pt-4 px-4 pb-20 text-center sm:block sm:p-0")}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              {...cLo("fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity")}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span {...cLo("hidden sm:inline-block sm:align-middle sm:h-screen")} aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div {...cLo("inline-block align-bottom bg-white rounded-lg",
              "px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl",
              "transform transition-all")}
            >
              {/*------ Content part ------*/}
              {checkmark &&
              <div {...cLo("mx-auto flex items-center justify-center",
                  "h-12 w-12 rounded-full bg-green-100")}
              >
                <CheckIcon {...cLo("h-6 w-6 text-green-600")} aria-hidden="true" />
              </div>}
              <div {...cLo("mt-3 text-center sm:mt-5")}>
                {title &&
                <Dialog.Title as="h3" {...cLo("text-lg leading-6 font-medium text-gray-900")}>
                  {title}
                </Dialog.Title>}
                {children}
              </div>
              {/*------ Content part ------*/}
              {(ok || cancel) &&
              <div {...cLo("mt-5 sm:mt-6 sm:grid",
                (ok && cancel) && "sm:grid-cols-2", "sm:gap-3 sm:grid-flow-row-dense")}
              >
                {ok &&
                <button
                  type="button"
                  {...cLo("w-full inline-flex justify-center rounded-md",
                    "border border-transparent shadow-sm px-4 py-2 bg-indigo-600",
                    "text-base font-medium text-white hover:bg-indigo-700",
                    "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                    (ok && cancel) && "sm:col-start-2", "sm:text-sm")}
                  onClick={() => handleClick(true)}
                  ref={btnRef}
                >
                  {ok}
                </button>}
                {cancel &&
                <button
                  type="button"
                  {...cLo("mt-3 w-full inline-flex justify-center rounded-md",
                    "border border-gray-300 shadow-sm px-4 py-2 bg-white",
                    "text-base font-medium text-gray-700 hover,:bg-gray-50",
                    "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                    (ok && cancel) && "sm:col-start-1", "sm:mt-0 sm:text-sm")}
                  onClick={() => handleClick(false)}
                >
                  {cancel}
                </button>}
              </div>}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
