/*=============================================================================
 LikeButton.tsx - Like Button with animations

 - Uncontrolled component: checked state is handled by the DOM

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
import { genUniqueId } from 'app/res';

export default function LikeButton({ onChange }: {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}) {
  const uId = genUniqueId();  // should be unique for each input and label element
  const aniSeq = [
    { tr: "24",    fill: ["#9FC7FA", "#9FC7FA"], c: [{x: 2.5, y: 3}, {x: 7.5, y: 2}] },
    { tr: "44 6",  fill: ["#CC8EF5", "#CC8EF5"], c: [{x: 2,   y: 2}, {x: 5,   y: 6}] },
    { tr: "52 28", fill: ["#8CE8C3", "#9CD8C3"], c: [{x: 4,   y: 2}, {x: 2,   y: 7}] },
    { tr: "35 50", fill: ["#F48EA7", "#F48EA7"], c: [{x: 6,   y: 5}, {x: 2,   y: 2}] },
    { tr: "14 50", fill: ["#91D2FA", "#91D2FA"], c: [{x: 6,   y: 5}, {x: 2,   y: 2}] },
    { tr: "0 28",  fill: ["#CC8EF5", "#91D2FA"], c: [{x: 2,   y: 7}, {x: 3,   y: 2}] },
    { tr: "7 6",   fill: ["#9CD8C3", "#8CE8C3"], c: [{x: 2,   y: 6}, {x: 5,   y: 2}] },
  ];
  return (
    <>
      <input
        type="checkbox"
        className="cb-like"
        id={uId}
        onChange={onChange}
      />
      <label htmlFor={uId}>
        <svg id="heart-like" width="26" viewBox="477 402 38 37">
          <g id="sym_heart" fill="none" fillRule="evenodd" transform="translate(467 392)">
            <path
              id="heart-path" fill="#AAB8C2"
              d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
            />
            <circle id="main-circ" fill="#E2264D" opacity="0" cx="29.5" cy="29.5" r="1.5"/>
            {aniSeq.map((s, i) =>
              <g key={i} id={`grp${i}`} opacity="0" transform={`translate(${s.tr})`}>
                <circle id="oval1" fill={s.fill[0]} cx={s.c[0].x} cy={s.c[0].y} r={2} />
                <circle id="oval2" fill={s.fill[1]} cx={s.c[1].x} cy={s.c[1].y} r={2} />
              </g>
            )}
          </g>
        </svg>
      </label>
    </>
  );
}
