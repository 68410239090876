/*=============================================================================
 Tags.tsx - Tag System

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useRef, } from 'react';
import { cLo, } from 'utils/util';
import { SvgIcon, } from 'utils/svg';
import EMOJIS from 'asset/emojis.json';  // object properties are sorted on load
// react-aria ------------------------------------------------------------------------------------
import { useButton, }            from '@react-aria/button';
import type { AriaButtonProps, } from '@react-types/button';
// react-aria ------------------------------------------------------------------------------------
import 'styles/Aqua.scss';

/**
 * To avoid No Index Signature Error for the bracket (Obj[key])
 */
export type TEmojisKey = keyof typeof EMOJIS;
export const getEmoji = (k: TEmojisKey) => EMOJIS[k];

/**
 * Show emoji when matching found
 * EMOJIS are sorted as a JavaScript object beforehand.
 */
export const TagEmoji = ({ tag }: { tag: string; }) => {
  return (
    <span>
      {EMOJIS.hasOwnProperty(tag) &&
        <span className="mr-1">{getEmoji(tag as TEmojisKey)}</span>}
      {tag}
    </span>
  );
}

interface ITagButtonProps extends AriaButtonProps {
  tag:       string;
  selected?: boolean;
}
/**
 * Tag button
 * consider data-attribute for tags
 */
export const ButtonTag: IFClassName<ITagButtonProps> = ({
  className,
  tag,
  selected,
  ...props
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton(props, ref);
  return (
    <button {...{ref}}
      {...buttonProps}
      {...cLo("BtnC BC_Tag", className, isPressed && "border-red-400",
        selected ? "BT_On" : "BT_Off")}
      data-tag={tag}
    >
      <TagEmoji {...{tag}} />
      {props.children}
    </button>
  );
}

export interface ITagInputProps {
  tags:      string[];
  setTags:  (list: string[]) => void;  // callback when tags input done (ENTER)
  maxTags?:  number;      // maximum tags allowed
}
/**
 * Tag Input Editor
 *  +-------------------------------------+
 *  | [tag1(x)] [tag2(x)] [tag3(x)] tag4  |
 *  +-------------------------------------+
 *  Show border for the whole list not on the real input field.
 */
export const TagInput: IFClassName<ITagInputProps> = ({
  className,
  tags, setTags, maxTags = 6,
}) => {
  const [noCompo, setNoCompo] = useState(true);  // not in IME composition mode
  const [value, setValue] = useState('');
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    // if (ev.target.value.includes(' ')) { return; }  // commented out to allow copy&paste
    if (tags.length >= maxTags)
      return;  // limit tags
    setValue(ev.target.value);
  }
  // To check if in IME mode
  const onCompositionStart = () => { setNoCompo(false); }
  const onCompositionEnd   = () => { setNoCompo(true); }
  const isUniqueTag = (candidate: string) =>
    !tags.some(tag => tag.toLowerCase() === candidate.toLowerCase());  // case-insensitive
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    const tVal = value.trim();  // trimmed value
    if (tVal.length) {
      if (noCompo) {
        if ( ev.key === ' '
          || ev.key === 'Enter') {
          ev.preventDefault();
          if (tags.length >= maxTags)
            return;
          if (tVal.includes(' ')) {  // possible input when copy&pasted
            let aValue = tVal.split(' ').filter(tag => isUniqueTag(tag));
            if (tags.length + aValue.length >= maxTags)
              aValue = aValue.slice(0, maxTags - tags.length);
            setTags([...tags, ...aValue]);
          } else if (isUniqueTag(tVal)) {
            setTags([...tags, tVal]);
          }
          setValue('');
        } else if (ev.key === 'Escape') {
          setValue('');
        }
      }
    } else {  // empty input field
      if (tags.length) {
        if (noCompo && (ev.key === 'Enter')) {
          // use current tags
        } else if (isKeyReleased) {
          if ( ev.key === 'Escape'
            || ev.key === 'Backspace') {
            ev.preventDefault();
            const tagsCopy = [...tags];  // for immutability
            const poppedTag = tagsCopy.pop();  // remove the last item
            setTags(tagsCopy);
            if (poppedTag && (ev.key === 'Backspace'))
              setValue(poppedTag);  // put tag in the edit field
          }
        }
      }
    }
    setIsKeyReleased(false);
  }
  const onKeyUp = () => {
    setIsKeyReleased(true);
  }
  const deleteTag = (index: number) => {
    setTags(tags.filter((_tag, i) => i !== index));
  }
  return (
    <div {...cLo(className, "flex flex-row justify-start text-sm",
      "border-none rounded-md text-gray-800 bg-gray-50")}
    >
      {tags.map((tag, i) =>
        <TagItem key={tag} onDeleteCB={() => deleteTag(i)}>{tag}</TagItem>)}
      <input
        {...cLo("m-1 w-full focus:outline-none bg-transparent",
           "placeholder-gray-300 dark:placeholder-gray-700")}
        placeholder={tags.length < maxTags ? "Enter a tag" : `Up to ${maxTags} tags`}
        {...{ value, onKeyDown, onKeyUp, onChange, onCompositionStart, onCompositionEnd }}
      />
    </div>
  );
}

interface ITagItemProps extends React.HTMLAttributes<HTMLDivElement> {
  className?:  string;      // AquaSmall, AquaCircle, ...
  bgColors?:   string;      // background colors
  onDeleteCB?: () => void;  // callback when delete the tag
}
/**
 * Tag item
 * @param children tag string
 * @param onDeleteCB callback when closing button clicked - the button will be shown
 * only when the callback is given
 */
export const TagItem: React.FC<ITagItemProps> = ({
  children,
  className = "AquaSmall",
  bgColors = "from-blue-900 to-blue-400 border border-blue-600",
  onDeleteCB,
  ...props
}) => {
  return (
    <div {...cLo("px-1.5 mx-0.5 my-1 text-white TextShadow",
      className, "bg-gradient-to-b", bgColors)}
      {...props}
    >
      <TagEmoji tag={children as string} />
      {onDeleteCB &&
      <button
        type='button'
        {...cLo("ml-1 border border-red-600 rounded-full",
          "hover:scale-125 bg-red-400 shadow-md")}
        onClick={onDeleteCB}
      >
        <SvgIcon {...cLo("w-3 h-3")} Path="ico_error" strokeWidth={0} vLen={20} />
      </button>}
    </div>
  );
}
