/*=============================================================================
 TeX.tsx - KaTeX typescript react encapsulation of KaTex.renderToString()

 - import 'katex/dist/katex.min.css';
 - Forked from @matejmazur/react-katex

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useState, useEffect, memo, } from 'react';
import type { ReactElement, ElementType, ComponentPropsWithoutRef, } from 'react';
import KaTeX from 'katex';
import type { ParseError, KatexOptions } from 'katex';
import 'katex/dist/katex.min.css';  // use katex.css to debug

/**
 * https://www.erikverweij.dev/blog/making-your-components-extensible-with-typescript/
 */
type TeXProps = ComponentPropsWithoutRef<'div'> &
  Partial<{
    as:          ElementType;
    math:        string | number;
    block:       boolean;    // displayMode: default false inline
    errorColor:  string;
    renderError: (error: ParseError | TypeError) => ReactElement;
    settings:    KatexOptions;
  }>;
/**
 * TeX using KaTeX
 */
const TeX: React.FC<TeXProps> = ({
  children,
  math,
  block,
  errorColor,
  renderError,
  settings,
  as: asComponent,
  ...props
}) => {
  const Compo = asComponent || (block ? 'div' : 'span');
  const content = (children ?? math) as string;  // ??: nullish coalescing operator allows '',0
  const [state, setState] = useState<
    { innerHtml: string } | { errorElement: React.ReactElement }
  >({ innerHtml: '' });

  useEffect(() => {
    try {
      const innerHtml = KaTeX.renderToString(content, {
        displayMode: !!block,
        errorColor,
        throwOnError: !!renderError,
        output: 'html',  // do not generate mathml block shows outer scrollbar, bug?
        ...settings,     // additional options https://katex.org/docs/options.html
      });

      setState({ innerHtml });
    } catch (error) {
      if ( error instanceof KaTeX.ParseError
        || error instanceof TypeError )
      {
        setState( renderError
          ? { errorElement: renderError(error) }
          : { innerHtml: error.message } );
      } else {
        throw error;
      }
    }
  }, [block, content, errorColor, renderError, settings]);

  if ('errorElement' in state) {
    return state.errorElement;
  }

  return (
    <Compo
      {...props}
      dangerouslySetInnerHTML={{ __html: state.innerHtml }}
    />
  );
};

export default memo(TeX);
