/*=============================================================================
 Reactions.tsx - Chat Reaction buttons

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { SvgIcon, SvgIconFull, } from 'utils/svg';
import { cLo, } from 'utils/util';

type TReactions = 'DELETED'|'REPLY'|'LIKE';  // DELETED is from THideMsgReason
export interface IReactionOnMsg {
  id:       TMsgID;
  reaction: TReactions;
};
/**
 * Chat reactions
 * delete, reply, like
 */
export const ChatReactions = ({ id, idSelf, reactionCB }: {
  id:         TMsgID;
  idSelf:     boolean;
  reactionCB: (react: IReactionOnMsg) => void;
}) => {
  // SvgIcon Wrapper
  const ButtonResponse: React.FC< Partial<IButtonProps> > = ({ onClick, children }) =>
    <button
      type="button"
      {...cLo("Chat_reaction")}
      {...{onClick}}
    >
      {children}
    </button>;

  const handleClickDelete: TButtonClickHandler = (_e) => {
    reactionCB({ id, reaction: 'DELETED'});
  }
  const handleClickReply: TButtonClickHandler = (_e) => {
    reactionCB({ id, reaction: 'REPLY'});
  }
  const handleClickLike: TButtonClickHandler = (_e) => {
    reactionCB({ id, reaction: 'LIKE'});
  }

  return idSelf
  ? <ButtonResponse onClick={handleClickDelete}>
      <SvgIcon Path="trash_delete" vLen={40} />
    </ButtonResponse>
  : <>
      <ButtonResponse onClick={handleClickReply}>
        <SvgIconFull Path="arrow_reply" vLen={20} />
      </ButtonResponse>
      <ButtonResponse onClick={handleClickLike}>
        <SvgIconFull Path="heart_like" />
      </ButtonResponse>
    </>;
}
