/*=============================================================================
 ChatParse.tsx - Chat Parse window including Bubbles and Embeded documents
 - each single chat message
 - text analysis
   - multilines: \n
   - URLs: https?://
   - math expressions: $$KaTeX$$

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
import { cC, cL, cLo } from 'utils/util';
import type { IMsg } from 'models';
import { RegEx_URL, RegEx_Cont } from 'utils/RegEx';
import { Bubble }            from './Bubble';
import { MathView, mathCfg } from './MathView';
import { EmbedView, urlCfg } from './EmbedView';
import type { IChatProps } from 'features/chat/PaneChat';

interface IChatParseProps extends Omit<IChatProps, 'isNewDate'> {
  readonly m: IMsg,  // message
};
/**
 * Single chat message parsed in a Chat Bubble and any Embeded contents
 *
 * 1. text
 * 2. Math  - inside the chat bubble
 * 3. Embed - outside the bubble
 */
const ChatParse = ({ m, idSelf, isNewId, bdrClr }: IChatParseProps) => {
  if (!m.text && !m.img)
    return null;
  //---------------------------------------------------------------------------
  const twBblWidth = "max-w-xs md:max-w-lg";  // bubble width
  // const twBblWidth = "max-w-xs";  // bubble width
  //---------------------------------------------------------------------------
  const twBorder = cL(bdrClr, idSelf ? "border-r-2" : "border-l-4");

  let text = m.text;
  let aURL:  string[] = [];  // array of URLs
  let aMath: string[] = [];
  if (text) {
    // Parse URLs ---------------------------------------------------------------
    const urls = text.match(RegEx_URL.proto);
    if (urls) {
      text = text.replace(RegEx_URL.proto, urlCfg.replacer);
      aURL = [...urls];
    }
    // Parse Math Equations -----------------------------------------------------
    const maths = text.match(RegEx_Cont.math_eq_inc);  // full matching including delimeters
    if (maths) {
      text = text.replace(RegEx_Cont.math_eq_inc, mathCfg.replacer);
      aMath = [...maths];
    }
  }
  if (m.img)  // img field DEPRECATED, just for backward compatibility
    aURL.push(m.img);
  const hasEmbed = !!aURL.length;
  return (
    <div className="flex flex-col">
      {text &&
      <Bubble
        {...cLo(twBblWidth, idSelf ? "Chat_attr_self" : "Chat_attr",
            cC(twBorder, hasEmbed, "rounded-b-none", "border-b"))}
        {...{text}}
        {...(isNewId && { sender: m.sender })}
      >
        {!!aMath.length &&
        <MathView
          {...cLo(idSelf ? "text-right" : "text-left")}
          {...{aMath}}
        />}
      </Bubble>}
      {hasEmbed &&
      <EmbedView
        {...cLo(twBblWidth)}
        clsContent={twBorder}
        {...{aURL}}
      />}
    </div>
  );
}
export default ChatParse;
