/*=============================================================================
 EmbedView.tsx - Embed viewer including URLs and documents

 - parent ChatContent parses the text

 - https://github.com/paulirish/lite-youtube-embed
   https://github.com/kylemocode/react-lite-yt-embed

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState } from 'react';
import { cLo, cCo, OPEN_NEW_WINDOW } from 'utils/util';
import { LiteYoutubeEmbed } from 'react-lite-yt-embed';
import { RegEx_URL, RegEx_Cont } from 'utils/RegEx';
import { ModalDlg, } from 'ui/ModalDlgTw';

export const urlCfg = {
  replacer: '',  // to be replaced with the url string
};

type TContentType = 'img'|'ytb'|'ist'|'web';

interface IEmbedView extends IClassNameObj {
  readonly aURL: string[];    // array of URLs
  clsContent:    TClassName;  // className for <a>
};
/**
 * render embedded content specified with url
 */
export const EmbedView = ({ aURL, className, clsContent }: IEmbedView) =>
  <ul {...cLo(className, "text-xs text-blue-500 italic underline")}>
    {aURL.map((url, i) =>
      <li key={i} className={clsContent}>
        <RenderContent {...{url}} />
      </li>)}
  </ul>;

const contentType = (url: string): TContentType => {
  if (url.match(RegEx_Cont.img))       return 'img';  // image
  if (url.match(RegEx_URL.youtube))    return 'ytb';  // youtube
  if (url.match(RegEx_URL.instagram))  return 'ist';  // instagram
                                       return 'web';  // webpage
}

const RenderContent = ({ url }: { url: string; }) => {
  const cT = contentType(url);
  switch(cT) {
    case 'img': return <EmbedImage   {...{url}} />;
    case 'ytb': return <EmbedYouTube {...{url}} />;
    default:    return <EmbedWebpage {...{cT, url}} />;
  }
}

 /*
  <a href={url}
    className="text-3xs truncate"
    {...OPEN_NEW_WINDOW}
  >
    <img src={url} alt="" />
  </a>;
 */
const EmbedImage = ({ url }: { url: string }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <img src={url} alt=""
        {...cLo("cursor-pointer")}
        onClick={() => setOpen(true)}
      />
      <ModalDlg {...{open, setOpen}}
        ok="OK"
        onOkCancel={() => setOpen(false)}
      >
        <img src={url} alt="" {...cLo("w-full")} />
      </ModalDlg>
    </>
  );
}
//  className="flex w-56 p-1 md:w-88"

/**
 * Embed YouTube - Extract YouTube ID (11 bytes long)
 */
const EmbedYouTube = ({ url }: { url: string }) => {
  const m = url.match(RegEx_Cont.youtubeId);
  if (m) {
    const id = m[1];  // capturing group 1
    //  defaultPlay={true}
    return ( //  className="w-72 md:w-96"
      <div className="w-[300px] md:w-[500px]">
        <LiteYoutubeEmbed {...{id}} mute={false} />
      </div>
    );
  }
  return null;
}
/*
    <iframe
      title       = "YouTube"
      width       = {400}
      height      = {300}
      src         = {`https://www.youtube.com/embed/${vID}?enablejsapi=1`}
      frameBorder = "0"
      allow       = "accelerometer; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
 */
// autoplay=1&mute=1&  autoplay;

/**
 * Extract domain from the url and limit the length and then show as a button
 * https://www.instagram.com/p/CMtOguth98J/embed <- Insta url should be in this form!
 */
const EmbedWebpage = ({ cT, url }: { cT: TContentType, url: string }) => {
  const isInsta = cT === 'ist';
  const [show, setShow] = useState(isInsta);
  return (
    <>
      <a href={url}
        className="text-xs ml-1 truncate"
        {...OPEN_NEW_WINDOW}
      >
        Open <span className="font-bold">{isInsta ? 'Instagram' : 'Webpage'}</span>
      </a>
      <button
        {...cCo("m-0.5 p-1 text-white TextShadow rounded-md", show,
          "dark:bg-pink-700 bg-pink-500",
          "dark:bg-blue-700 bg-blue-500")}
        onClick={() => setShow(!show)}
      >
        {show ? 'hide' : 'show'}
      </button>
      {show && <iframe
        title       = {cT}
        width       = {isInsta ? 300 : 400}
        height      = {400}
        src         = {url}
        frameBorder = "1"
        scrolling   = "no"
        allowFullScreen
      />}
    </>
  );
}

/*
    <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v7.0&appId=301405370454910&autoLogAppEvents=1"></script>
      <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwsj%2Fvideos%2F276560983497022%2F&show_text=0&width=560" width="560" height="315" title="WSJ"></iframe>
      <br/>
  <iframe
    title ="instagram"
    src="https://www.instagram.com/p/B3aztQPFoTQ/embed"
    width="320"
    height="400"
    frameBorder="1"
    scrolling="no"
    allowTransparency={true}>
  </iframe>
 */
